import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutRow extends SproutFieldBase<string> {
  options: {key: string, label: any, name: any, value: any, labelLocalized: string}[] = [];

  controlType = SproutFieldType.row;
  modelInd: false;
  type: string;
  dataType: SproutDataType.row;

  public fields: SproutFieldBase<any>[];

  constructor(options: {} = {}, fields: SproutFieldBase<any>[]) {
    super(options);
    this.fields = fields;
  }

}
