import {CommonModule} from '@angular/common';
import {DoBootstrap, Injector, NgModule} from '@angular/core';
import {createCustomElement} from '@angular/elements';

import {SproutFormsLibraryComponent} from "../../../sprout-forms-library/src/lib/sprout-forms-library.component";
import {SproutFormsLibraryModule} from "../../../sprout-forms-library/src/lib/sprout-forms-library.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InboxComponent} from "../../../sprout-forms-library/src/lib/components/inbox/inbox.component";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SproutFormsLibraryModule,
    BrowserAnimationsModule
  ],
  entryComponents: [SproutFormsLibraryComponent, InboxComponent],
  providers: [],
  bootstrap: []
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) { }

  ngDoBootstrap() {
    console.log('AppModule.ngDoBootstrap');

    const ngElementSproutForms = createCustomElement(SproutFormsLibraryComponent, {
      injector: this.injector
    });
    const ngElementSproutInbox = createCustomElement(InboxComponent, {
      injector: this.injector
    });

    if (!customElements.get('sprout-forms')) {
      customElements.define('sprout-forms', ngElementSproutForms);
    }
    if (!customElements.get('sprout-inbox')) {
      customElements.define('sprout-inbox', ngElementSproutInbox);
    }

  }
}
