// @ts-ignore
import {Component, Input, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
// @ts-ignore
import {FormGroup, Validators} from '@angular/forms';
// @ts-ignore
import {DomSanitizer} from '@angular/platform-browser';
// @ts-ignore
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
// @ts-ignore
import {SproutFieldBase} from '../../classes/sprout-field-base';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {SproutFormMetaService} from "../../services/sprout-form-meta.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-sprout-field',
  templateUrl: './sprout-field.component.html',
  styleUrls: ['./sprout-field.component.css'],
  providers: [SproutExpressionService],
  encapsulation: ViewEncapsulation.None
})
// @ts-ignore
export class SproutFieldComponent implements OnInit {

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  // @ts-ignore
  @Input() field: SproutFieldBase<any>;
  // @ts-ignore
  @Input() form: FormGroup;

  protected ngZone: NgZone;

  public i18n: any;

  safeType: string;

  labelLocalized$: Observable<String>;

  labelHasExpression = false;

  pageDirty = false;

  protected sanitizer: DomSanitizer;
  protected sproutExpressionService: SproutExpressionService;
  protected sproutFormMetaService: SproutFormMetaService;
  protected localeService: LocaleService;

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              sproutFormMetaService: SproutFormMetaService,
              localeService: LocaleService,
              ngZone: NgZone) {
    this.sanitizer = sanitizer;
    this.sproutExpressionService = sproutExpressionService;
    this.sproutFormMetaService = sproutFormMetaService;
    this.localeService = localeService;
    this.ngZone = ngZone;

    const pageDirtyChange = this.sproutFormMetaService.pageDirty$.subscribe($event => {
      // console.log('sproutFormMetaService pageDirty changed: $event:');
      // console.dir($event);
      this.pageDirty = $event as boolean;
    });

  }

  isRequired(): boolean {
    return this.field.required;
  }

  ngOnInit(): void {
    this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
      this.field.labelLocalized = result;
    }).unsubscribe();

    this.i18n = this.localeService.translations;

    if (this.field.safe === true) {
      this.safeType = 'html';
    }

    this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);

    if (!this.labelHasExpression && this.field.options) {
      for (const option of this.field.options) {
        if (this.sproutExpressionService.hasVariables(option.label)) {
          this.labelHasExpression = true;
        }
      }
    }

    if (this.field && this.field.requiredExpression && this.field.requiredExpression.length > 0) {
      const variablesRequired: string[] = this.sproutExpressionService.extractVariables(this.field.requiredExpression);
      this.sproutExpressionService.calculateObservable(this.field.requiredExpression, this.form.value).subscribe(result => {
        if ((/true/i).test(result)) {
          this.form.controls[this.field.key].setValidators([Validators.required]);
          this.field.required = true;
        } else {
          this.form.controls[this.field.key].clearValidators();
          this.field.required = false;
        }
        this.form.controls[this.field.key].updateValueAndValidity();
      }).unsubscribe();
      const model = {};
      variablesRequired.forEach(variable => {
        if (this.form.get(variable)) {
          this.form.get(variable).valueChanges.subscribe(next => {
            model[variable] = next;
            this.sproutExpressionService.calculateObservable(this.field.requiredExpression, model).subscribe(result => {
              if ((/true/i).test(result)) {
                  this.form.controls[this.field.key].setValidators([Validators.required]);
                  this.field.required = true;
              } else {
                this.form.controls[this.field.key].clearValidators();
                this.field.required = false;
              }
              this.form.controls[this.field.key].updateValueAndValidity();
            }).unsubscribe();
          });
        }
      });
    }

    let variablesOptions: string[] = null;

    this.localeService._locale$.subscribe(locale => {
      this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
        this.field.labelLocalized = result;
      }).unsubscribe();

      if (this.field.controlType &&
        (this.field.controlType === SproutFieldType.radio ||
          this.field.controlType === SproutFieldType.select ||
          this.field.controlType === SproutFieldType.likert ||
          this.field.controlType === SproutFieldType.slider
        )) {
        const optionField: any = this.field;
        for (const option of optionField.options) {
          const variablesOptionsTmp: string[] = this.sproutExpressionService.extractVariables(JSON.stringify(option.label));
          option.labelLocalized = this.sproutExpressionService.compileSync(option.label, this.form.value);

          if (variablesOptionsTmp != null && variablesOptionsTmp.length > 0) {
            for (const variable of variablesOptionsTmp) {
              if (variablesOptions == null) {
                variablesOptions = [];
                variablesOptions.push(variable);
              } else {
                let variableExists = false;
                for (const variableTmp of variablesOptions) {
                  if (variable === variableTmp) {
                    variableExists = true;
                  }
                }
                if (!variableExists) {
                  variablesOptions.push(variable);
                }
              }
            }
          }

        }
      }
    });

    if ((this.field.conditionalExpression && this.field.conditionalExpression.length > 0) ||
      (this.field.expression && this.field.expression.length > 0) || null ||
      this.labelHasExpression) {
      // if ((this.field.conditionalExpression && this.field.conditionalExpression.length > 0) ||
      //   (this.field.expression && this.field.expression.length > 0) ||
      //   this.labelHasExpression) {

      let variablesConditionalExpression: string[] = null;
      let variablesExpression: string[] = null;
      let variablesLabel: string[] = null;

      if (this.field.conditionalExpression && this.field.conditionalExpression.length > 0) {
        variablesConditionalExpression = this.sproutExpressionService.extractVariables(this.field.conditionalExpression);
      }

      if (this.field.expression && this.field.expression.length > 0) {
        variablesExpression = this.sproutExpressionService.extractVariables(this.field.expression);
      }

      if (this.labelHasExpression) {
        variablesLabel = this.sproutExpressionService.extractVariables(this.field.label);
      }

      if (variablesConditionalExpression && variablesConditionalExpression.length > 0) {
        this.sproutExpressionService.calculateObservable(this.field.conditionalExpression, this.form.value).subscribe(result => {
          this.field.display = (/true/i).test(result);
          if (!this.field.display && this.form.dirty) {
            setTimeout(() => {
              // console.log('%c1) Clearing value for ' + this.field.key + ' with ' + (this.form.dirty ? 'dirty' : 'clean') + ' form.', 'background: #222; color: #bada55');
              this.form.controls[this.field.key].patchValue("");
            });
          }
        });
        const modelConditionalExpressions = {};
        variablesConditionalExpression.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.pipe(
              debounceTime(100),
              startWith(''),
              distinctUntilChanged()
            ).subscribe(next => {
              // console.log('%c 1: ' + variable + ' ==> ' + next, 'background: #222; color: #ff0000');
              modelConditionalExpressions[variable] = next;
              // tslint:disable-next-line:max-line-length
              this.sproutExpressionService.calculateObservable(this.field.conditionalExpression, modelConditionalExpressions).subscribe(result => {
                this.field.display = (/true/i).test(result);
                if (!this.field.display && this.form.dirty) {
                  setTimeout(() => {
                    // console.log('%c2) Clearing value for ' + this.field.key + ' with ' + (this.form.dirty ? 'dirty' : 'clean') + ' form.', 'background: #222; color: #bada55');
                    this.form.controls[this.field.key].patchValue("");
                  });
                }
              }).unsubscribe();
            });
          }
        });
      }
      if (variablesExpression && variablesExpression.length > 0) {
        // console.log(' ==> ngOnInit.this.field: ' + this.field.name);
        const variablesExpressionModel = {};
        variablesExpression.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.controls[variable].valueChanges.pipe(
              debounceTime(100),
              startWith(''),
              distinctUntilChanged()
            ).subscribe(next => {
              variablesExpressionModel[variable] = next;

              // if (this.field.name === 'hidden_dementia_sum3') {
              //   console.log('%c hidden_dementia_sum3 responding to change to variable: ' + variable + ' with value of ' + next, 'background: #222; color: #22ddb2');
              //   console.dir(variablesExpressionModel);
              //   console.table(variablesExpressionModel);
              //   console.log('this.form.controls[\'hidden_dementia_helper1\'].value: ' + this.form.controls['hidden_dementia_helper1'].value);
              // }

              this.sproutExpressionService.calculateObservable(this.field.expression, variablesExpressionModel).subscribe(result => {
                // console.log('%c setting ' + this.field.name + ' to ' + result, 'background: #222; color: #34fd22');

                this.field.value = result;
                if (this.form && this.form.controls[this.field.key]) {
                  setTimeout(() => {
                    this.form.controls[this.field.key].patchValue(this.field.value);
                  });
                }
              }).unsubscribe();
            });
          }
        });
      }
      if (variablesLabel && variablesLabel.length > 0) {
        const variablesModel = {};
        variablesLabel.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.pipe(
              debounceTime(100),
              startWith(''),
              distinctUntilChanged()
            ).subscribe(next => {
              variablesModel[variable] = next;
                this.sproutExpressionService.compileObservable(this.field.label, variablesModel).subscribe(result => {
                  setTimeout(() => {
                    this.field.labelLocalized = result;
                  });
                }).unsubscribe();
            });
          }
        });
      }

      if (variablesOptions && variablesOptions.length > 0) {
        const variablesOptionsModel = {};
        variablesOptions.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.pipe(
              debounceTime(100),
              startWith(''),
              distinctUntilChanged()
            ).subscribe(next => {
              let minTmp = -1;
              let maxTmp = -1;
              const optionField: any = this.field;
              variablesOptionsModel[variable] = next;
              // setTimeout(() => {
                for (const option of optionField.options) {
                  this.sproutExpressionService.compileObservable(option.label, variablesOptionsModel).subscribe(result => {
                    setTimeout(() => {
                      option.labelLocalized = result;
                    });
                    if (this.field.controlType === SproutFieldType.slider) {
                      if (minTmp === -1 || minTmp > option.key) {
                        minTmp = option.key;
                      }
                      if (maxTmp === -1 || maxTmp < option.key) {
                        maxTmp = option.key;
                      }
                    }
                  }).unsubscribe();
                }
              // });

            });
          }
        });
      }
    }
  }

  onRegisterFocus(key: string) {
    // console.log('onRegisterFocus.key: ' + key);
    // sessionStorage.setItem("sproutFormFieldKey"/, key);
    this.sproutFormMetaService.setFieldFocus(key);
  }
}
