// @ts-ignore
import {Component, Input, OnInit} from '@angular/core';
// @ts-ignore
import {FormGroup} from '@angular/forms';
// @ts-ignore
import {DomSanitizer} from '@angular/platform-browser';
// @ts-ignore
import {startWith} from 'rxjs/operators';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutRow} from '../../classes/sprout-row';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';

@Component({
  selector: 'app-sprout-row',
  templateUrl: './sprout-row.component.html',
  styleUrls: ['./sprout-row.component.css']
})
// @ts-ignore
export class SproutRowComponent implements OnInit {

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  // @ts-ignore
  @Input() row: SproutRow;
  // @ts-ignore
  @Input() form: FormGroup;

  columns: number;

  labelHasExpression = false;
  safeType: string;

  protected sanitizer: DomSanitizer;
  protected sproutExpressionService: SproutExpressionService;
  protected localeService: LocaleService;

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              localeService: LocaleService) {
    this.sanitizer = sanitizer;
    this.sproutExpressionService = sproutExpressionService;
    this.localeService = localeService;
  }

  ngOnInit() {

  }
  ngOnInitblah() {
    let columnCounter = 0;

    this.row.fields.forEach(field => {
      columnCounter += field.colspan;
    });

    // this.columns = this.row.fields.length;
    this.columns = columnCounter;

    if (this.row.conditionalExpression && this.row.conditionalExpression.length > 0) {
      this.sproutExpressionService.calculateObservable(this.row.conditionalExpression, this.form.value).subscribe(result => {
        this.row.display = (/true/i).test(result);
      }).unsubscribe();
    }

    if (this.row.style && this.row.style.length > 0) {
      this.row.styleSanitized = this.sanitizer.bypassSecurityTrustStyle(this.row.style);
    }

    if (this.row && this.row.conditionalExpression && this.row.conditionalExpression.length > 0) {
      const variables: string[] = this.sproutExpressionService.extractVariables(this.row.conditionalExpression);
      if (variables && variables.length > 0) {
        const variablesModel = {};
        variables.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.subscribe(next => {
              variablesModel[variable] = next;
              this.sproutExpressionService.calculateObservable(this.row.conditionalExpression, variablesModel).subscribe(result => {
                this.row.display = (/true/i).test(result);
              }).unsubscribe();
            });
          }
        });
      }
    }

    this.sproutExpressionService.compileObservable(this.row.label, this.form.value).subscribe(result => {
      this.row.labelLocalized = result;
    }).unsubscribe();

    const variablesOptions: string[] = this.sproutExpressionService.extractVariables(JSON.stringify(this.row.label));

    this.labelHasExpression = variablesOptions && variablesOptions.length > 0;

    this.localeService._locale$.subscribe(locale => {
      this.sproutExpressionService.compileObservable(this.row.label, this.form.value).subscribe(result => {
        this.row.labelLocalized = result;
        this.labelHasExpression = this.sproutExpressionService.hasVariables(this.row.label);
      }).unsubscribe();
    });

    // if (this.row.safe === true) {
    this.safeType = 'html';
    // }

    if (this.labelHasExpression) {

      // console.log('ngOnInit.variablesOptions:');
      // console.dir(variablesOptions);

      const variablesOptionsModel = {};

      variablesOptions.forEach(variable => {
        if (this.form.get(variable)) {
          this.form.get(variable)
            .valueChanges
            .pipe(startWith(null))
            .subscribe(next => {
              variablesOptionsModel[variable] = next;
              this.sproutExpressionService.compileObservable(this.row.label, variablesOptionsModel).subscribe(result => {
                this.row.labelLocalized = result;
              }).unsubscribe();
            });
        }
      });
    }
  }
}
