import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  imports: [MatButtonModule, MatInputModule, MatFormFieldModule, MatCardModule, MatRadioModule
    , MatSelectModule, MatCheckboxModule, MatSliderModule, MatSlideToggleModule, MatToolbarModule,
    MatGridListModule, MatDividerModule, MatStepperModule],
  exports: [MatButtonModule, MatInputModule, MatFormFieldModule,  MatCardModule, MatRadioModule, MatSelectModule
    , MatCheckboxModule, MatSliderModule, MatSlideToggleModule, MatToolbarModule, MatGridListModule, MatDividerModule,
    MatStepperModule],
})
export class MaterialModule { }
