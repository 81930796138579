// @ts-ignore
import {Injectable} from '@angular/core';
import {SproutForm} from '../interfaces/sprout-form';
import {SproutField} from '../interfaces/sprout-field';
// @ts-ignore
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {StatusTO} from "../classes/status-to";

@Injectable()
// @ts-ignore
export class SproutFormMetaService {

  private _status = new Subject();
  status$ = this._status.asObservable();

  private _pageDirty = new Subject();
  pageDirty$ = this._pageDirty.asObservable();

  setStatus(status: StatusTO) {
    this._status.next(status);
  }

  setPageDirty(dirty: boolean) {
    this._pageDirty.next(dirty);
  }

  private _fieldFocus = new Subject();
  fieldFocus$ = this._fieldFocus.asObservable();

  setFieldFocus(fieldKey: string) {
    this._fieldFocus.next(fieldKey);
  }

  private _formValue = new Subject();
  formValue$ = this._formValue.asObservable();

  setFormValue(value: any) {
    this._formValue.next(value);
  }

  private _form: SproutForm;
  private  _fieldMap: Map<string, SproutField> = new Map<string, SproutField>();
  private _formSource: BehaviorSubject<SproutForm> =  new BehaviorSubject(this._form);
  private _conditionFieldsArray: SproutField[];
  _sproutFormMetaService$: Observable<SproutForm> = this._formSource.asObservable();

  constructor() { }

  get form(): SproutForm {
    return this._form;
  }

  set form(sproutForm: SproutForm) {
    this._form = sproutForm;

    this._formSource.next(sproutForm);

    if (sproutForm && sproutForm.model) {
      sproutForm.model.forEach(page => {
        if (page.composites && page.composites.length > 0) {
          page.composites.forEach(composite => {
            if (composite.fields && composite.fields.length > 0) {
              composite.fields.forEach(field => {

                // console.log('field.type: ' + field.controlType);
                if (field.controlType && field.controlType === SproutFieldType.row) {
                  if (field.fields && field.fields.length > 0) {
                    field.fields.forEach(fieldRow => {
                      if (fieldRow.modelInd === true) {
                        if (fieldRow.key) {
                          this._fieldMap.set(fieldRow.key, fieldRow);
                        }
                        if (fieldRow.conditionalExpression && fieldRow.conditionalExpression.trim().length > 0) {
                          if (!this._conditionFieldsArray) { this._conditionFieldsArray = []; }
                          this._conditionFieldsArray.push(fieldRow);
                        }
                      }
                    });
                  }
                } else {
                  if (field.key) {
                    this._fieldMap.set(field.key, field);
                  }
                  if (field.conditionalExpression && field.conditionalExpression.trim().length > 0) {
                    if (!this._conditionFieldsArray) { this._conditionFieldsArray = []; }
                    this._conditionFieldsArray.push(field);
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  get fieldMap(): Map<string, SproutField> {
    return this._fieldMap;
  }

  set fieldMap(value: Map<string, SproutField>) {
    this._fieldMap = value;
  }

  get conditionFieldsArray(): SproutField[] {
    return this._conditionFieldsArray;
  }

  set conditionFieldsArray(value: SproutField[]) {
    this._conditionFieldsArray = value;
  }

}
