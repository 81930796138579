export class StatusTO {
  status: string;
  messageCode: string;
  message: string;

  constructor(status: string, messageCode: string, message: string) {
    this.status = status;
    this.messageCode = messageCode;
    this.message = message;
  }
}
