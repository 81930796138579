import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutLabelField extends SproutFieldBase<string> {
  fieldType = 'SproutLabelField';
  controlType = SproutFieldType.label;
  modelInd: false;
  type: string;
  dataType: SproutDataType;

  constructor(options: {} = {}) {
    super(options);
    // console.log('constructor.options: ');
    // console.dir(options);
    this.type = options['type'] || '';
    this.dataType = options['dataType'] || SproutDataType.text;
  }
}
