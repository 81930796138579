import {Injectable} from '@angular/core';
import {Locale} from '../interfaces/locale';
import {BehaviorSubject, Observable} from 'rxjs';
import {translations} from '../../i18n/translations';
import {SproutControlItem} from "../classes/sprout-control-item";

@Injectable()
export class LocaleService {

  private _localeDefault: Locale = {code: 'en', name: 'English'};
  private _locale: Locale = this._localeDefault;
  private _locales: Locale[] = [];
  private _localeSource: BehaviorSubject<Locale> =  new BehaviorSubject(this._locale);
  _locale$ = this._localeSource.asObservable();
  private _showDropdown = false;

  private _translations = {};

  constructor() {
    this.initLocales();
  }

  get localeDefault(): Locale {
    return this._localeDefault;
  }

  set locale(locale: Locale) {
    this._locale = locale;
    this._localeSource.next(this._locale);
    this.initSproutTranslations();
  }

  get locales(): Locale[] {
    return this._locales;
  }

  get locale$(): Observable<Locale> {
    return this._locale$;
  }

  get showDropdown(): boolean {
    return this._showDropdown;
  }

  set showDropdown(value: boolean) {
    this._showDropdown = value;
  }

  initLocales() {
    this._locales.push({code: 'en', name: 'English'});
    this._locales.push({code: 'es', name: 'Español'});
    this._locales.push({code: 'fr', name: 'Français'});

    this.initSproutTranslations();
  }

  initSproutTranslations() {
    // console.log('initSproutTranslations.translations:');
    // console.dir(translations);

    this._translations = {};

    // console.log('initSproutTranslations.this._locale.code:');
    // console.dir(this._locale.code);

    let keySet = new Set();
    let localeSet = new Set();

    translations.forEach((value: any, key: string) => {
      // console.log('=================');
      // console.log(key, value);
      localeSet.add(key);
      for (const key in value) {
        keySet.add(key);
      }
    });

    keySet.forEach((key: string) => {
      // console.log('key: ' + key);
      const textMap: any = {};
      localeSet.forEach((locale: string) => {
        textMap[locale] = translations.get(locale)[key];
      });

      const sproutControlItem = new SproutControlItem(textMap);

      sproutControlItem.labelLocalized = this.localize(sproutControlItem.label);
      this._translations[key] = sproutControlItem;

    });

    // console.log('initSproutTranslations.this.translations[key]');
    // console.dir(this._translations);

  }

  localize(expressionRaw: any): string {
    if (typeof expressionRaw === 'object') {
      let expressionLocale: string = expressionRaw[this._locale.code];

      if (!expressionLocale && this._locale.code !== this.localeDefault.code) {
        expressionLocale = expressionRaw[this.localeDefault.code];
      }

      return expressionLocale;
    } else if (typeof expressionRaw === 'string') {
      return expressionRaw;
    }
  }

  get translations(): {} {
    return this._translations;
  }
}
