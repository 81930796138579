<div [formGroup]="form">
  <mat-form-field class="sprout-full-width" *ngIf="field.display !== false">
    <input
      matInput
      [min]="minDate"
      [max]="maxDate"
      [formControlName]="field.key"
      [matDatepicker]="picker"
      [id]="field.key"
      [type]="field.dataType"
      [placeholder]="field.labelLocalized"
      [required]="field.required"
      appMaskDate
      #sproutDateInput
    >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <!--        <mat-hint>Errors appear instantly!</mat-hint>-->
<!--    <pre>-->
<!--      {{form.controls[field.key].errors|json}}-->
<!--    </pre>-->
    <mat-error *ngIf="form.controls[field.key].hasError('required')">
      {{i18n['errorTextRequired']}}
    </mat-error>
    <mat-error *ngIf="form.controls[field.key].hasError('matDatepickerMin')">
      {{i18n['errorTextMinDate']}} {{form.controls[field.key].errors['matDatepickerMin'].min | date:'longDate'}}
    </mat-error>
    <mat-error *ngIf="form.controls[field.key].hasError('matDatepickerMax')">
      {{i18n['errorTextMaxDate']}} {{form.controls[field.key].errors['matDatepickerMax'].max | date:'longDate'}}
    </mat-error>
  </mat-form-field>
</div>
