// @ts-ignore
import {Component, OnDestroy, OnInit} from '@angular/core';
// @ts-ignore
import {Subscription} from 'rxjs';
// @ts-ignore
import {FormControl, FormGroup} from '@angular/forms';
// @ts-ignore
import {FlatTreeControl} from '@angular/cdk/tree';
// @ts-ignore
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
// @ts-ignore
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

import {Locale} from '../../interfaces/locale';
import {SproutForm} from '../../interfaces/sprout-form';
import {SproutFormDataService} from '../../services/sprout-form-data.service';
import {LocaleService} from '../../services/locale.service';
import {SproutFormMetaService} from '../../services/sprout-form-meta.service';
import {SproutFieldGroup} from '../../interfaces/sprout-field-group';
import {SproutTextField} from '../../classes/sprout-text-field';
import {SproutDataType} from '../../enums/sprout-data-type.enum';
import {SproutTextareaField} from '../../classes/sprout-textarea-field';
import {MetaInfo} from '../../interfaces/meta-info';
import {SproutPage} from '../../classes/sprout-page';

const WIDGET_DATA: SproutFieldGroup[] = [
  {
    name: 'Text Fields',
    description: 'Text Input fields',
    children: [
      new SproutTextField({
        key: 'number1',
        label: {
          'en': 'Enter a number',
          'es': 'Ingrese un número',
          'fr': 'Entrez un nombre'
        },
        value: 2,
        required: true,
        dataType: SproutDataType.number,
        order: 0
      })
    ]
  },
  {
    name: 'Textarea Fields',
    description: 'Textarea Input fields',
    children: [
      new SproutTextareaField({
        key: 'description',
        label: {
          'en': 'Please describe yourself',
          'es': 'Por favor describese',
          'fr': 'Décrivez-vous'
        },
        required: false,
        conditionalExpression: '[shareDescription] == "Y"',
        order: 3
      })
      ]
  }
];

/**
 * Food data with nested structure.
 * Each node has a name and an optiona list of children.
 */
/*
interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [
      {name: 'Apple'},
      {name: 'Banana'},
      {name: 'Fruit loops'},
    ]
  }, {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [
          {name: 'Broccoli'},
          {name: 'Brussel sprouts'},
        ]
      }, {
        name: 'Orange',
        children: [
          {name: 'Pumpkins'},
          {name: 'Carrots'},
        ]
      },
    ]
  },
];
*/

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.css']
})
// @ts-ignore
export class DesignerComponent implements OnInit, OnDestroy {

  showFiller = true;

  panelOpenState = false;

  widgets = WIDGET_DATA;

  locale: Locale;
  locales: Locale[];
  // form: SproutForm;
  sproutForm: SproutForm;

  subscription: Subscription;

  localePickerForm: FormGroup;

  referenceInd: boolean;

  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];

  private _transformer = (node: any, level: number) => {

    // console.dir(node);
    // console.log('Object.getPrototypeOf(node): ' + Object.getPrototypeOf(node));
    //
    // console.log('_transformer: ' + node.META_NAME);

    if (node.children) {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        level: level,
      };
    } else {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.META_NAME,
        prototype: node,
        level: level,
      };
    }

  }



  drop(event: CdkDragDrop<string[]>) {
    console.log('drop');
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  // tslint:disable-next-line:member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  // tslint:disable-next-line:member-ordering
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  // tslint:disable-next-line:member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  constructor(
    private sproutFormDataService: SproutFormDataService,
    private localeService: LocaleService,
    private sproutFormMetaService: SproutFormMetaService) {

    this.dataSource.data = WIDGET_DATA;

    // this.referenceInd = this.route.snapshot.queryParams.ref;

  }

  ngOnInit(): void {

    this.sproutFormDataService.getSproutFormReference().subscribe(formModel => {
      console.log('formModel:');
      console.dir(formModel);

      const metaInfo: MetaInfo = {formName: 'Sample Sprout Form'};

      this.sproutForm = {
        meta: metaInfo,
        model: formModel as SproutPage[],
        value: undefined
      };

      this.sproutFormMetaService.form = this.sproutForm;

      this.locales = this.localeService.locales;
      this.locale = this.locales[0];

      this.localePickerForm = new FormGroup({
        locale: new FormControl(this.locale),
      });

      this.localePickerForm.controls['locale'].valueChanges.subscribe((locale: Locale) => {
        this.localeService.locale = locale;
      });


    });
    // }).unsubscribe();


    // this.sproutForm = this.sproutFormDataService.getSproutForm();
    // this.sproutFormMetaService.form = this.sproutForm;
    //
    // this.locales = this.localeService.locales;
    // this.locale = this.locales[0];
    //
    // this.localePickerForm = new FormGroup({
    //   locale: new FormControl(this.locale),
    // });
    //
    // this.localePickerForm.controls['locale'].valueChanges.subscribe((locale: Locale) => {
    //   this.localeService.locale = locale;
    // });

    // this.subscription = this.sproutFormMetaService._sproutFormMetaService$.subscribe(form => {
    //   this.form = form;
    // });

  }

  changeLocal() {
    // console.log('changeLocal: ' + this.locale);
    this.localeService.locale = this.locale;
  }

  getLocales() {
    return this.localeService.locales;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addComponent(): void {
    console.log('addComponent');
  }

}
