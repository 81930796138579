import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import {SproutFormDataService} from "../../services/sprout-form-data.service";
import {InstanceLiteTO} from "../../classes/instance-lite-to";
import {InboxCommandTO} from "../../classes/inbox-command-to";
import {SproutExpressionService} from "../../services/sprout-expression.service";
import {LocaleService} from "../../services/locale.service";
import {SproutFormMetaService} from "../../services/sprout-form-meta.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'sprout-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css'],
  providers: [SproutFormDataService, SproutExpressionService, LocaleService, SproutFormMetaService],
  encapsulation: ViewEncapsulation.None
})
export class InboxComponent implements OnInit {

  @Input()
  baseUrl = 'http://localhost:8084';

  @Input('locale')
  public localeCode: string;

  @Input('subscriber')
  public subscriber = false;

  @Output()
  command = new EventEmitter();

  showDelay = 0;

  filter: string;
  nameFilter = false;

  initComplete = false;

  displayedColumns: string[] = ['name', 'statusName', 'activityDate', 'form', 'output'];
  // displayedColumns: string[] = ['name', 'statusName', 'activityDate', 'rename', 'hide', 'form', 'output'];
  // displayedColumns: string[] = ['instanceKey', 'name', 'statusName', 'activityDate'];
  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  instances: InstanceLiteTO[];

  @Inject(LOCALE_ID) public locale: string

  public i18n: any;

  constructor(private sproutFormDataService: SproutFormDataService,
    private sproutExpressionService: SproutExpressionService,
    private localeService: LocaleService
  ) {
    // this.i18n = localeService.translations;
    this.i18n = {};
  }

  ngOnInit() {
    setTimeout(() => {
      this.initializeInbox();
    }, 1000);
  }

  initializeInbox() {

    this.sproutFormDataService.baseUrl = this.baseUrl;

    this.sproutFormDataService.getSproutFormInbox().subscribe(response => {
      if (response) {
        this.instances = response as InstanceLiteTO[];
        // console.log('InstanceLiteTO.ngOnInit.this.instances:');
        // console.dir(this.instances);
      }
      this.initComplete = true;
    });

    // console.log('initializeInbox.this.localeCode');
    // console.dir(this.localeCode);

    this.localeService.showDropdown = false;

    if (this.localeCode) {
      const locales = this.getLocales();
      if (locales && locales.length > 0) {
        for (let localeTmp of locales) {
          if (localeTmp.code === this.localeCode) {
            this.localeService.locale = localeTmp;
            this.localeService.showDropdown = false;
          }
        }
      }
    }

    // this.sproutExpressionService.compileObservable(this.openFormText.label, null).subscribe(result => {
    //   this.openFormText.labelLocalized = result;
    // }).unsubscribe();

    this.i18n = this.localeService.translations;

    // console.log('initializeInbox.this.i18n');
    // console.dir(this.i18n);
    // console.log('initializeInbox.i18n.openFormText.labelLocalized: ' + this.i18n.openFormButton.labelLocalized);

  }

  getLocales() {
    return this.localeService.locales;
  }

  onOpenForm(instanceLiteTO: InstanceLiteTO) {
    // console.log('onOpenForm.instanceLiteTO:');
    // console.dir(instanceLiteTO);
    const inboxCommandTO = new InboxCommandTO();
    inboxCommandTO.action = 'openForm';
    inboxCommandTO.instanceKey = instanceLiteTO.instanceKey;
    this.command.emit(inboxCommandTO);
  }

  onOpenOutput(instanceLiteTO: InstanceLiteTO) {
    // console.log('onOpenOutput.instanceLiteTO:');
    // console.dir(instanceLiteTO);
    const inboxCommandTO = new InboxCommandTO();
    inboxCommandTO.action = 'openOutput';
    inboxCommandTO.instanceKey = instanceLiteTO.instanceKey;
    this.command.emit(inboxCommandTO);
  }

  onNewForm() {
    const inboxCommandTO = new InboxCommandTO();
    inboxCommandTO.action = 'newForm';
    this.command.emit(inboxCommandTO);
  }

  onManageSubscriptions() {
    const inboxCommandTO = new InboxCommandTO();
    inboxCommandTO.action = 'manageSubscriptions';
    this.command.emit(inboxCommandTO);
  }

  displayedColumns2 = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
  isMobile: boolean;

  editMap: boolean[] = [];
  editValueMap: string[] = [];

  onToggleEdit(index: number, instance: InstanceLiteTO) {
    // console.log('onToggleEdit', index, instance);
    // if (!instance.name) instance.name = instance.publicationName;
    this.editValueMap[index] =  instance.name ? instance.name : instance.publicationName;
    this.editMap[index] = !this.editMap[index]
  }

  onSaveEdit(index: number, instance: InstanceLiteTO) {
    // console.log('onSaveEdit', instance);
    this.editMap[index] = !this.editMap[index]
    instance.name = this.editValueMap[index]
    this.sproutFormDataService.renameInstance(instance).subscribe(booleanTO => {
      // console.log('response', booleanTO);
    })
  }

  onRenameKeydown($event: KeyboardEvent, index: number, instance: InstanceLiteTO) {
    if ($event.key === "Enter") {
      // console.log('onRenameKeydown enter');
      this.onSaveEdit(index, instance)
    } else if ($event.key === "Escape") {
      // console.log('onRenameKeydown escape');
      this.onToggleEdit(index, instance)
    }

  }

  onFilter(filter: string) {
  // onFilter($event: KeyboardEvent, filter: string) {
    console.log('onFilter', filter);
  }

  onToggleFilter() {
    this.nameFilter = !this.nameFilter;
  }
}



// export class TableBasicExample {
//   displayedColumns = ['position', 'name', 'weight', 'symbol'];
//   dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
// }

export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: Element[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];
