<!--<div *ngIf="composite.display !== false" style="border: 1px #dd1f5a solid;" class="card">-->
<div style="margin-top: 20px;">
<!--  <mat-card>-->
<!--    <mat-card-header style="background-color: #dd1f5a">-->
<!--      <h2 style="color: white">{{composite.key}}</h2>-->
<!--    </mat-card-header>-->
<!--    <mat-card-content>-->
<!---->
<!--      <mat-card-subtitle>-->
        <h3 *ngIf="composite && composite.labelLocalized">
          <span *ngIf="safeType" [innerHtml]="composite.labelLocalized | safe: safeType"></span>
          <span *ngIf="safeType == null">{{composite.labelLocalized}}</span>
        </h3>
<!--      </mat-card-subtitle>-->

      <div *ngFor="let formComponent of fields" [class]="formComponent.display === true ? 'form-row' : 'form-row-hidden'">
        <app-sprout-row *ngIf="formComponent.controlType === SproutFieldType.row && formComponent.display !== false"
                        [row]="formComponent" [form]="form"></app-sprout-row>
        <app-sprout-field *ngIf="formComponent.controlType !== SproutFieldType.row" [field]="formComponent"
                          [form]="form"></app-sprout-field>
      </div>
<!--      <div *ngFor="let formComponent of fields" [class]="formComponent.display === true ? 'form-row' : 'form-row-hidden'">-->
<!--        <app-sprout-row *ngIf="formComponent.controlType === SproutFieldType.row && formComponent.display !== false"-->
<!--                        [row]="formComponent" [form]="form"></app-sprout-row>-->
<!--        <app-sprout-field *ngIf="formComponent.controlType !== SproutFieldType.row" [field]="formComponent"-->
<!--                          [form]="form"></app-sprout-field>-->
<!--      </div>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->

</div>


