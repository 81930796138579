<!--<mat-card>-->
<!--  <mat-card-header style="background-color: #56dd3a">-->
<!--    <h2 style="color: white">{{row.key}}</h2>-->
<!--  </mat-card-header>-->
<!--  <mat-card-content>-->


  <h3>
    <span *ngIf="row && row.labelLocalized">
      <span *ngIf="safeType" [innerHtml]="row.labelLocalized | safe: safeType"></span>
      <span *ngIf="safeType == null">{{row.labelLocalized}}</span>
    </span>
  </h3>

    <table cellspacing="10">
      <tr>
        <td *ngFor="let field of row.fields">
          <app-sprout-field [field]="field" [form]="form" style="text-align: left;"></app-sprout-field>
        </td>
      </tr>
    </table>
<!--</mat-card-content>-->
<!--</mat-card>-->



<!--
<mat-grid-list [cols]="columns" rowHeight="50px">
  <mat-grid-tile *ngFor="let field of row.fields" [colspan]="field.colspan">
    <app-sprout-field [field]="field" [form]="form" style="text-align: left;"></app-sprout-field>
  </mat-grid-tile>
</mat-grid-list>
-->
