// @ts-ignore
import {Component, NgZone, OnInit} from '@angular/core';
// @ts-ignore
import {DomSanitizer} from '@angular/platform-browser';

import {SproutFieldComponent} from '../sprout-field/sprout-field.component';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutFormMetaService} from "../../services/sprout-form-meta.service";

@Component({
  selector: 'app-sprout-likert',
  templateUrl: './sprout-likert.component.html',
  styleUrls: ['./sprout-likert.component.css']
})
// @ts-ignore
export class SproutLikertComponent extends SproutFieldComponent implements OnInit {

  tiles: any[];
  questions: any[];
  columns: number;

  protected ngZone: NgZone;

  // private sanitizer: DomSanitizer;
  // private sproutExpressionService: SproutExpressionService;
  // private localeService: LocaleService;

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              sproutFormMetaService: SproutFormMetaService,
              localeService: LocaleService,
              ngZone: NgZone) {
    super(sanitizer, sproutExpressionService, sproutFormMetaService, localeService, ngZone);
  }

  ngOnInit() {

    if (this.field.options && this.field.options.length > 0) {
      this.columns = this.field.options.length + 1;

      this.tiles = [];
      this.questions = [];
      this.tiles.push({text: '', cols: 1, rows: 1, color: '#efefef'});

      for (const option of this.field.options) {
        this.sproutExpressionService.compileObservable(option.label, this.form.value).subscribe(result => {
          option.labelLocalized = result;
        }).unsubscribe();
      }

      for (const question of this.field.questions) {
        this.sproutExpressionService.compileObservable(question.label, this.form.value).subscribe(result => {
          question.labelLocalized = result;
          this.questions.push({
            key: question.key,
            labelLocalized: question.labelLocalized,
            type: SproutFieldType.label,
            label: question.label
          });
          for (const option of this.field.options) {
            this.questions.push({key: option.key, labelLocalized: option.labelLocalized, type: SproutFieldType.radio, label: option.label});
          }
        }).unsubscribe();
      }

      this.localeService._locale$.subscribe(locale => {
        this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
          this.field.labelLocalized = result;
        }).unsubscribe();
        this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);

        for (const option of this.field.options) {
          this.sproutExpressionService.compileObservable(option.label, this.form.value).subscribe(result => {
            option.labelLocalized = result;
          }).unsubscribe();
        }

        for (const question of this.field.questions) {
          this.sproutExpressionService.compileObservable(question.label, this.form.value).subscribe(result => {
            question.labelLocalized = result;
          }).unsubscribe();
        }

        for (const question of this.questions) {
          this.sproutExpressionService.compileObservable(question.label, this.form.value).subscribe(result => {
            question.labelLocalized = result;
          }).unsubscribe();
        }

      });

    }
  }

}
