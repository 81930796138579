import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutOptionBase<T> extends SproutFieldBase<string> {
  options: {key: string, label: any, name: any, value: any, labelLocalized: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
