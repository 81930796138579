import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import {SproutTextField} from '../classes/sprout-text-field';
import {SproutDataType} from '../enums/sprout-data-type.enum';
import {SproutCalculationField} from '../classes/sprout-calculation-field';
import {SproutSpanField} from '../classes/sprout-span-field';
import {SproutDivField} from '../classes/sprout-div-field';
import {SproutTextareaField} from '../classes/sprout-textarea-field';
import {SproutHeadingField} from '../classes/sprout-heading-field';
import {SproutLabelField} from '../classes/sprout-label-field';
import {SproutRadioField} from '../classes/sprout-radio-field';
import {SproutSelectField} from '../classes/sprout-select-field';
import {SproutCheckboxField} from '../classes/sprout-checkbox-field';
import {SproutSliderField} from '../classes/sprout-slider-field';
import {SproutLikertField} from '../classes/sprout-likert-field';
import {SproutPage} from '../classes/sprout-page';
import {SproutComposite} from '../classes/sprout-composite';
import {SproutRow} from '../classes/sprout-row';
import {SproutBlank} from '../classes/sprout-blank';
import {InstanceTO} from "../classes/instance-to";
import {InstanceOutputTO} from "../classes/instance-output-to";
import {InstanceLiteTO} from "../classes/instance-lite-to";

@Injectable()
export class SproutFormDataService {

  private _baseUrl: string;

  public instance: InstanceTO;

  // @Output()
  // status = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  // public setStatus(statusMessage: string) {
  //   this.status.emit(statusMessage);
  // }

  public submitForm(model: any, command: string, location?: string) {
    // console.log('submitForm.model: ' + model);
    // console.dir(model);

    if (this.instance && model) {
      if (typeof model === 'object') {
        this.instance.model = JSON.stringify(model);
      } else if (typeof model === 'string') {
        this.instance.model = model;
      }

      this.instance.location = location;
      this.instance.command = command;

      return this.http.post(this._baseUrl + '/rest/form/submit', this.instance);
    }
    return null;
  }

  public getSproutForm(instanceTO: InstanceTO): Observable<Object> {
    // console.log('getSproutForm.setting instanceTO: ');
    // console.dir(instanceTO);
    //   this.instance = instanceTO;
    if (instanceTO && (instanceTO.instanceKey || instanceTO.publicationKey)) {
      return this.http.post(this._baseUrl + '/rest/form/instance', instanceTO);
    }
    return null;
  }

  public getFormModelFromKey(formKey: string): Observable<Object> {
    // console.log('getSproutForm.setting instanceTO: ');
    // console.dir(instanceTO);
    //   this.instance = instanceTO;
    if (formKey) {
      return this.http.post(this._baseUrl + '/rest/form/key', formKey);
    }
    return null;
  }

  public getSproutFormOutput(instanceOutputTO: InstanceOutputTO): Observable<Object> {
    if (instanceOutputTO && instanceOutputTO.instanceKey && instanceOutputTO.outputId) {
      return this.http.post(this._baseUrl + '/rest/form/output', instanceOutputTO);
    }
    return null;
  }

  public getSproutFormOutputList(instanceTO: InstanceTO): Observable<Object> {
    if (instanceTO && instanceTO.publicationKey) {
      return this.http.post(this._baseUrl + '/rest/form/output/list', instanceTO);
    }
    return null;
  }

  public getSproutFormInbox(): Observable<Object> {

    // console.log('getSproutFormInbox.this._baseUrl: ' + this._baseUrl + '/rest/inbox');

      // return this.http.post(this._baseUrl + '/rest/inbox', null);
      return this.http.get(this._baseUrl + '/rest/inbox');
  }

  public getSproutFormReference(): Observable<Object> {

    // this._baseUrl = 'http://localhost:8084/rest/test';
    // console.log('getSproutForm.this._baseUrl: ' + this._baseUrl);
    //
    // if (!referenceInd) {
    //   const model: SproutPage[] = [];
    //   return of(model);
    // return this.http.get(this._baseUrl, {});
    // } else {
    const model: SproutPage[] = [
      new SproutPage('page1', {
        en: 'Page One',
        es: 'Página Uno',
        fr: 'Page Un'
      }, [
        new SproutComposite([new SproutTextField({
          key: 'firstName',
          label: {
            en: 'First name',
            es: 'Nombre',
            fr: 'Prénom'
          },
          value: '',
          dataType: SproutDataType.text,
          required: true,
          order: 1
        })]),
        new SproutComposite([new SproutTextField({
            key: 'lastName',
            label: {
              en: 'Last name',
              es: 'Apellido',
              fr: 'Nom de Famille'
            },
            value: '',
            required: true,
            dataType: SproutDataType.text,
            // conditionalExpression: '[sum] > 100',
            // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
            order: 2
          }),
            new SproutRow({
              key: 'heightRow',
              conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
            }, [
              new SproutLabelField({
                key: 'heightLabel',
                label: {
                  'en': 'What is your height (feet and inches):',
                  'es': 'Cuál es tu altura (pies y pulgadas):',
                  'fr': 'Quelle est votre taille (pieds et pouces):'
                },
                show: true,
                style: 'font-size: 0.8em;',
                modelInd: false,
                order: 6,
                colspan: 2,
              }),
              new SproutTextField({
                key: 'heightFeet',
                label: {
                  en: 'Feet',
                  es: 'Pies',
                  fr: 'Pieds'
                },
                value: '',
                required: true,
                dataType: SproutDataType.number,
                // conditionalExpression: '[sum] > 100',
                // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
                order: 2
              }),
              new SproutTextField({
                key: 'heightInches',
                label: {
                  en: 'Inches',
                  es: 'Pulgadas',
                  fr: 'Pouces'
                },
                value: '',
                required: true,
                dataType: SproutDataType.number,
                // conditionalExpression: '[sum] > 100',
                // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
                order: 2
              }),
              new SproutBlank({
                key: 'blank',
                show: true,
                colspan: 3,
                style: 'background-color: #ccc; color: #000; padding: 5px; border-radius: 4px; border: 1px solid #4f4f4f;',
                class: 'card',
                order: 6
              })])],
          {
            // conditionalExpression: '[firstName].toUpperCase() == "STEPHENO"'
          })]),
      new SproutPage('page2', {
        en: 'Page Two',
        es: 'Página Dos',
        fr: 'Page Deux'
      }, [
        new SproutComposite([
          new SproutCheckboxField({
            key: 'likePuppies',
            label: {
              en: 'Do you like puppies?',
              es: '¿Se gustan usted los cachorros?',
              fr: 'Aimes-tu les chiots?'
            },
            value: false,
            required: false,
            dataType: SproutDataType.checkbox,
            // conditionalExpression: '[sum] > 100',
            // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
            order: 2
          })]),
        new SproutComposite([new SproutLikertField({
          key: 'likertSatisfaction',
          label: {
            en: 'How would you rate your experience?',
            es: '¿Cómo calificaría su experiencia?',
            fr: 'Comment noteriez-vous votre expérience?'
          },
          value: false,
          required: false,
          dataType: SproutDataType.likert,
          questions: [
            {
              key: '0', label: {
                'en': 'I would recommend this doctor to a friend',
                'es': 'Recomendaría este doctor a un amigo',
                'fr': 'Je recommanderais ce médecin à un ami'
              }
            },
            {
              key: '1', label: {
                'en': 'I was seen on time',
                'es': 'Me vieron a tiempo',
                'fr': 'J\'ai été vu à l\'heure'
              }
            },
            {
              key: '2', label: {
                'en': 'It was easy to make an appointment',
                'es': 'Fue fácil hacer una cita',
                'fr': 'Il était facile de prendre rendez-vous'
              }
            },
          ],
          options: [
            {key: '0', label: {'en': 'Strongly Disagree', 'es': 'Muy en desacuerdo', 'fr': 'Fortement en désaccord'}},
            {key: '1', label: {'en': 'Disagree', 'es': 'En desacuerdo', 'fr': 'En désaccord'}},
            {key: '2', label: {'en': 'Agree', 'es': 'De acuerdo', 'fr': 'D\'accord'}},
            {key: '3', label: {'en': 'Strongly Agree', 'es': 'Totalmente de acuerdo', 'fr': 'Tout à fait d\'accord'}}
          ],
          // conditionalExpression: '[sum] > 100',
          // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
          order: 2
        }),
          new SproutSliderField({
            key: 'surveyOfLikes',
            label: {
              en: 'What do you like?',
              es: '¿Qué se gusta?',
              fr: 'Qu\'est-ce que tu aimes'
            },
            value: false,
            required: false,
            options: [
              {key: '1', label: {'en': 'Strongly Disagree', 'es': 'Muy en desacuerdo', 'fr': 'Fortement en désaccord'}},
              {key: '10', label: {'en': 'Strongly Agree', 'es': 'Totalmente de acuerdo', 'fr': 'Tout à fait d\'accord'}}
            ],
            order: 2
          }),
          new SproutRadioField({
            key: 'shareDescription',
            label: {
              'en': 'Would you like to describe yourself?',
              'es': '¿Se gustaría describese?',
              'fr': 'Aimeriez-vous vous décrire?'
            },
            options: [
              {key: 'Y', label: {'en': 'Yes', 'es': 'Sí', 'fr': 'Oui'}},
              {key: 'F', label: {'en': 'No', 'es': 'No', 'fr': 'Non'}}
            ],
            required: false,
            order: 3
          })])], {
        conditionalExpression: '[heightFeet] === 6'
      })
      ,
      new SproutPage('page3', {
        en: 'Color Page',
        es: 'Página de los Colores',
        fr: 'Page Trois'
      }, [
        new SproutComposite([
          new SproutSelectField({
            key: 'favoriteColor',
            label: {
              'en': 'What is your favorite color?',
              'es': '¿Cuál es su color preferido?',
              'fr': 'Quelle est votre couleur préférée?'
            },
            options: [
              {key: 'B', label: {'en': 'Blue', 'es': 'Azul', 'fr': 'Bleu'}},
              {key: 'R', label: {'en': 'Red', 'es': 'Rojo', 'fr': 'Rouge'}},
              {key: 'G', label: {'en': 'Green', 'es': 'Verde', 'fr': 'Vert'}}
            ],
            required: false,
            order: 3
          }),
          new SproutTextareaField({
            key: 'description',
            label: {
              'en': 'Please describe yourself',
              'es': 'Por favor describese',
              'fr': 'Décrivez-vous'
            },
            required: false,
            conditionalExpression: '[shareDescription] == "Y"',
            order: 3
          }),
          new SproutTextField({
            key: 'number1',
            label: {
              'en': 'Enter a number',
              'es': 'Ingrese un número',
              'fr': 'Entrez un nombre'
            },
            value: 2,
            required: true,
            dataType: SproutDataType.number,
            order: 3
          }),
          new SproutTextField({
            key: 'number2',
            label: {
              'en': 'CALC("Enter another number other than " + [number1])',
              'es': 'Ingrese otro número',
              'fr': 'Entrez un autre nombre'
            },
            value: '',
            required: true,
            dataType: SproutDataType.number,
            order: 4
          }),
          new SproutCalculationField({
            key: 'sum',
            label: {
              'en': 'CALC("Sum of Numbers " + [number1] + " and " + [number2] + ": ")',
              'es': 'CALC("La suma de los números " + [number1] + " y " + [number2] + ": ")',
              'fr': 'CALC("La somme des nombres " + [number1] + " et " + [number2] + ": ")'
            },
            show: true,
            expression: '[number1] + [number2]',
            required: true,
            order: 5
          }),
          new SproutHeadingField({
            key: 'myFirstHeading',
            label: {
              'en': 'This is a &lt;heading>. This is red: <span style="background-color: red;">Red</span>.',
              'es': 'Esto es un &lt;heading>. Esto es rojo: <span style="background-color: red;">Rojo</span>.',
              'fr': 'C\'est un &lt;heading>. C\'est rouge: <span style="background-color: red;">Rouge</span>.'
            },
            show: true,
            safe: true,
            order: 6
          }),
          new SproutLabelField({
            key: 'myFirstLabel',
            label: {
              'en': 'This is a <label>.',
              'es': 'Esto es un <label>.',
              'fr': 'C\'est un <label>.'
            },
            show: true,
            order: 6
          }),
          new SproutSpanField({
            key: 'myFirstSpan',
            label: {
              'en': 'This is a <span>.',
              'es': 'Esto es un <span>.',
              'fr': 'C\'est un <span>.'
            },
            show: true,
            style: 'background-color: #ccc; color: #000; padding: 5px; border-radius: 4px; border: 1px solid #4f4f4f;',
            class: 'card',
            order: 6
          }),
          new SproutDivField({
            key: 'myFirstDiv',
            label: {
              'en': 'This is a <div>.',
              'es': 'Esto es un <div>.',
              'fr': 'C\'est un <span>.'
            },
            show: true,
            style: 'background-color: #ccc; color: #000; padding: 5px; border-radius: 4px; border: 1px solid #4f4f4f;',
            class: 'card',
            order: 6
          }),
          new SproutDivField({
            key: 'mySecondDiv',
            label: {
              'en': '<h1>This is a bunny, ' +
                '<img style="height: 150px;" src="https://www.petakids.com/wp-content/uploads/2015/11/Cute-Red-Bunny.jpg">' +
                '</img>.</h1>',
              'es': '<h1>Esto es un conejo, ' +
                '<img style="height: 150px;" src="https://www.petakids.com/wp-content/uploads/2015/11/Cute-Red-Bunny.jpg">' +
                '</img>.</h1>',
              'fr': '<h1>C\'est un lapin, ' +
                '<img style="height: 150px;" src="https://www.petakids.com/wp-content/uploads/2015/11/Cute-Red-Bunny.jpg">' +
                '</img>.</h1>'
            },
            show: true,
            safe: true,
            style: 'background-color: #fff; color: #000; padding: 5px; border-radius: 4px; border: 1px solid #4f4f4f;',
            class: 'card',
            order: 6
          })])
      ])

    ];
    // model.sort((a, b) => a.order - b.order);

    // const modelDebug = JSON.stringify(model);
    // console.log(modelDebug);
    // console.dir(model);

    return of(model);
    // }

    // const modelShort: SproutFieldBase<any>[] = [
    //
    //   new SproutTextField({
    //     key: 'firstName',
    //     label: {
    //       en: 'First name',
    //       es: 'Nombre',
    //       fr: 'Prénom'
    //     },
    //     value: 'Stephen',
    //     dataType: SproutDataType.text,
    //     required: true,
    //     order: 1
    //   }),
    //   new SproutTextField({
    //     key: 'lastName',
    //     label: {
    //       en: 'Last name',
    //       es: 'Apellido',
    //       fr: 'Nom de Famille'
    //     },
    //     value: 'Lorenz',
    //     required: true,
    //     dataType: SproutDataType.text,
    //     // conditionalExpression: '[sum] > 100',
    //     // conditionalExpression: '[firstName].toUpperCase() == "STEPHEN"',
    //     order: 2
    //   }),
    // ];


  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }


  public renameInstance(instance: InstanceLiteTO): Observable<Object> {
    if (instance && (instance.instanceKey)) {
      return this.http.post(this._baseUrl + '/rest/rename/instance', instance);
    }
  }

}
