import {SproutFieldBase} from './sprout-field-base';
import {SafeStyle} from '@angular/platform-browser';

export class SproutComposite {
  public fields: SproutFieldBase<any>[];

  key: string;
  label: any;
  labelLocalized: string;
  conditionalExpression: string;
  expression: string;
  class: string;
  style: string;
  safe: boolean;

  styleSanitized: SafeStyle;

  display = true;

  constructor(fields: SproutFieldBase<any>[], options: {
    conditionalExpression?: string,
    expression?: string,
    class?: string,
    style?: string,
    safe?: boolean,
  } = {}) {
    this.fields = fields;

    if (options) {
      this.conditionalExpression = options.conditionalExpression || null;
      this.expression = options.expression || null;
      this.class = options.class || null;
      this.style = options.style || null;
      this.safe = options.safe || false;
    }
  }
}
