<div style="height: 100%">

<!--
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="fill-remaining-space">Welcome to Sprout 2.0 Form Designer: {{sproutForm?.meta?.formName}}</span>

      <span class="example-spacer"></span>
      <mat-icon (click)="drawer.toggle()" class="example-icon" aria-hidden="false" aria-label="Example user verified icon">vertical_split</mat-icon>

    </mat-toolbar-row>
  </mat-toolbar>

  <div style="text-align:center" class="mat-typography">
    <form [formGroup]="localePickerForm">
      <mat-form-field class="sprout-full-width">
        <mat-select
          formControlName="locale"
        >
          <mat-option *ngFor="let locale of locales" [value]="locale">
            {{locale.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
-->


    <mat-drawer-container class="designer-container"  fullscreen>




      <mat-drawer #drawer class="designer-palette" mode="side" opened>


        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="todo"
          [cdkDropListConnectedTo]="[doneList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">



          <mat-accordion multi="true">
            <mat-expansion-panel *ngFor="let fieldType of widgets">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{fieldType.name}}
                </mat-panel-title>
<!--                <mat-panel-description>-->
<!--                  {{fieldType.description}}-->
<!--                </mat-panel-description>-->
              </mat-expansion-panel-header>


              <div
                cdkDropList
                #todoList="cdkDropList"
                [cdkDropListData]="todo"
                [cdkDropListConnectedTo]="[doneList]"
                class="example-list"
                (cdkDropListDropped)="drop($event)">

              <div *ngFor="let field of fieldType.children" cdkDrag>
                <div class="example-box">{{field.META_NAME}}</div>
              </div>
              </div>
<!--              <mat-form-field>-->
<!--                <input matInput placeholder="First name">-->
<!--              </mat-form-field>-->
            </mat-expansion-panel>
          </mat-accordion>
        </div>

<!--        <div-->
<!--          cdkDropList-->
<!--          #todoList="cdkDropList"-->
<!--          [cdkDropListData]="todo"-->
<!--          [cdkDropListConnectedTo]="[doneList]"-->
<!--          class="example-list"-->
<!--          (cdkDropListDropped)="drop($event)">-->
<!--          <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>-->
<!--        </div>-->

      </mat-drawer>

      <div class="designer-content">

        <div style="width: 100%">
          <mat-toolbar color="primary">
            <button type="button" mat-button (click)="drawer.toggle()">
              <i class="material-icons">
                apps
              </i>
            </button>
          </mat-toolbar>



          <div style="width: 100%">
            <div class="example-container">
              <h2>Form Design</h2>

              <div
                cdkDropList
                #doneList="cdkDropList"
                [cdkDropListData]="done"
                [cdkDropListConnectedTo]="[todoList]"
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
              </div>
            </div>
          </div>

        </div>




















      </div>

    </mat-drawer-container>

<!--</div>-->
</div>
