export const translations = new Map<string, any>();
translations.set('en', {
  "myFormsHeader": "My Forms",
  "myFormsText": "You can use this list of forms to open and complete an unfinished form or view generated output.",
  "formStatus-NEW": "New",
  "formStatus-OPEN": "Open",
  "formStatus-REVOKE": "Revoked",
  "formStatus-COMPLETE": "Completed",
  "formStatus-POST_PROCESSING": "Generating output...",
  "myFormsName": "Name",
  "myFormsStatus": "Status",
  "myFormsDate": "Date",
  "openFormButton": "Open Form",
  "viewOutputButton": "View Output",
  "errorTextRequired": "This field is required.",
  "errorTextMinDate": "Date cannot be before ",
  "errorTextMaxDate": "Date cannot be after ",
  "requiredErrorText": "This field is required.",
  "submitButtonText": "Submit",
  "saveButtonText": "Save",
  "previousButtonText": "Previous Page",
  "nextButtonText": "Next Page",
  "noFormsOne": "You do not appear to have any forms. Please click on the",
  "newFormButton": "New Form",
  "manageSubscriptions": "Manage Subscriptions",
  "noFormsThree": "button to complete your first form.",
  "loadingPleaseWait": "Loading...please wait...",
});
translations.set('es', {
  "myFormsHeader": "Mis formularios",
  "myFormsText": "Puede utilizar esta lista de formularios para abrir y completar un formulario incompleto o para ver el resultado generado.",
  "formStatus-NEW": "Nuevo",
  "formStatus-OPEN": "En progreso",
  "formStatus-REVOKE": "Revocado",
  "formStatus-COMPLETE": "Completado",
  "formStatus-POST_PROCESSING": "Generando informe...",
  "myFormsName": "Nombre",
  "myFormsStatus": "Estado",
  "myFormsDate": "Fecha",
  "openFormButton": "Abrir formulario",
  "viewOutputButton": "Ver resultado",
  "errorTextRequired": "Entrada obligatoria.",
  "errorTextMinDate": "la fecha no puede ser anterior de ",
  "errorTextMaxDate": "la fecha no puede ser posterior de ",
  "requiredErrorText": "Este campo es obligatorio.",
  "submitButtonText": "Enviar",
  "saveButtonText": "Guardar",
  "previousButtonText": "Página previa",
  "nextButtonText": "Página siguiente",
  "loadingPleaseWait": "Cargando... por favor, espere...",
  "noFormsOne": "No tiene ningún formulario. Por favor, haga clic en",
  "newFormButton": "Nuevo formulario",
  "manageSubscriptions": "Administrar Suscripciones",
  "noFormsThree": "el botón para completar su primer formulario.",
});
translations.set('fr', {
  "myFormsHeader": "My Forms",
  "myFormsText": "You can use this list of forms to open and complete an unfinished form or view generated output.",
  "formStatus-NEW": "New",
  "formStatus-OPEN": "Open",
  "formStatus-REVOKE": "Revoked",
  "formStatus-COMPLETE": "Completed",
  "formStatus-POST_PROCESSING": "Generating output...",
  "myFormsName": "Name",
  "myFormsStatus": "Status",
  "myFormsDate": "Date",
  "openFormButton": "Abierta Forma",
  "viewOutputButton": "Mira Nota",
  "errorTextRequired": "Entrée obligatoire.",
  "errorTextMinDate": "La date ne peut pas être avant ",
  "errorTextMaxDate": "La date ne peut pas être après ",
  "requiredErrorText": "Entrée obligatoire",
  "submitButtonText": "Enregistrer",
  "saveButtonText": "Conserve",
  "previousButtonText": "Page Précédente",
  "nextButtonText": "Page Suivante",
  "loadingPleaseWait": "Loading...please wait...",
  "noFormsOne": "You do not appear to have any forms. Please click on the",
  "newFormButton": "Gérer Les Abonnements",
  "manageSubscriptions": "Manage Subscriptions",
  "noFormsThree": "button to complete your first form.",
});
