<mat-grid-list [cols]="columns" rowHeight="50px">
  <mat-grid-tile class="sprout-likert-grid-header-blank"></mat-grid-tile>
  <mat-grid-tile
    *ngFor="let option of field.options"
    class="sprout-likert-grid-header">
    {{option.labelLocalized}}
  </mat-grid-tile>
  <span *ngFor="let question of field.questions ; let even = even; let odd = odd" [formGroup]="form">
    <mat-grid-tile class="sprout-likert-question" [ngClass]="{ 'sprout-likert-question-odd': odd, 'sprout-likert-question-even': even }">
      <!--{{question.labelLocalized}}; odd: {{odd}}; even: {{even}}-->
      {{question.labelLocalized}}
    </mat-grid-tile>
    <mat-radio-group class="sprout-full-width"
                     [formControlName]="field.key + question.key"
                     [id]="field.key + question.key"
                     [required]="field.required"
    >
      <mat-grid-tile class="mat-row"
         *ngFor="let option of field.options"
                     [ngClass]="{ 'sprout-likert-question-odd': odd, 'sprout-likert-question-even': even }">
        <mat-radio-button class="vertical-radio" [value]="option.key">
          <!--{{option.labelLocalized}}-->
        </mat-radio-button>
      </mat-grid-tile>
    </mat-radio-group>
  </span>
</mat-grid-list>
