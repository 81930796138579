import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {SproutFieldBase} from '../../classes/sprout-field-base';
import {FormGroup} from '@angular/forms';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutComposite} from '../../classes/sprout-composite';
import {DomSanitizer} from '@angular/platform-browser';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {pairwise, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-sprout-composite',
  templateUrl: './sprout-composite.component.html',
  styleUrls: ['./sprout-composite.component.css']
})
export class SproutCompositeComponent implements OnInit {

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  @Input() composite: SproutComposite;
  @Input() fields: SproutFieldBase<any>[];
  @Input() form: FormGroup;

  labelHasExpression = false;
  safeType: string;

  protected sanitizer: DomSanitizer;
  protected sproutExpressionService: SproutExpressionService;
  protected localeService: LocaleService;
  protected ngZone: NgZone;

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              localeService: LocaleService,
              changeDetector: ChangeDetectorRef,
              ngZone: NgZone) {
    this.sanitizer = sanitizer;
    this.sproutExpressionService = sproutExpressionService;
    this.localeService = localeService;
    this.ngZone = ngZone;
  }

  ngOnInit() {

    if (this.composite.safe === true) {
      this.safeType = 'html';
    }

    if (this.composite.conditionalExpression && this.composite.conditionalExpression.length > 0) {
      // tslint:disable-next-line:max-line-length
      this.sproutExpressionService.calculateObservable(this.composite.conditionalExpression, this.form.value).subscribe(result => {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            // console.log('determining this.composite.display for ' + this.composite.key);
            this.composite.display = (/true/i).test(result);
          });
        });
      }).unsubscribe();
    }

    if (this.composite.style && this.composite.style.length > 0) {
      this.composite.styleSanitized = this.sanitizer.bypassSecurityTrustStyle(this.composite.style);
    }

    if (this.composite && this.composite.conditionalExpression && this.composite.conditionalExpression.length > 0) {
      const model = {};

      const variables: string[] = this.sproutExpressionService.extractVariables(this.composite.conditionalExpression);
        if (variables && variables.length > 0) {
          variables.forEach(variable => {
            if (this.form.get(variable)) {
              this.form.get(variable).valueChanges.subscribe(next => {
                // tslint:disable-next-line:max-line-length
                // debugger;
                model[variable] = next;
                // this.sproutExpressionService.calculateObservable(this.composite.conditionalExpression, this.form.value).subscribe(result => {
                this.sproutExpressionService.calculateObservable(this.composite.conditionalExpression, model).subscribe(result => {
                  this.composite.display = (/true/i).test(result);
                }).unsubscribe();
              });
            }
          });
        }
    }

    this.sproutExpressionService.compileObservable(this.composite.label, this.form.value).subscribe(result => {
      this.composite.labelLocalized = result;
    }).unsubscribe();

    const variablesOptions: string[] = this.sproutExpressionService.extractVariables(JSON.stringify(this.composite.label));

    this.labelHasExpression = variablesOptions && variablesOptions.length > 0;

    this.localeService._locale$.subscribe(locale => {
      this.sproutExpressionService.compileObservable(this.composite.label, this.form.value).subscribe(result => {
        this.composite.labelLocalized = result;
      }).unsubscribe();
      // this.labelHasExpression = this.sproutExpressionService.hasVariables(this.composite.label);
    });

    // if (this.composite.safe === true) {
      this.safeType = 'html';
    // }

    if (this.labelHasExpression) {

      // console.log('ngOnInit.variablesOptions:');
      // console.dir(variablesOptions);

      const labelModel = {};

      variablesOptions.forEach(variable => {
        if (this.form.get(variable)) {
          this.form.get(variable)
            .valueChanges
            .pipe(startWith(null))
            .subscribe(next => {
              // console.log('variable changed: ' + this.field.key + ': ' + variable + ': ' + prev + ' ==> ' + next);
              // tslint:disable-next-line:max-line-length
              // const model = this.form.value;
              labelModel[variable] = next;
              this.sproutExpressionService.compileObservable(this.composite.label, labelModel).subscribe(result => {
                this.composite.labelLocalized = result;
              }).unsubscribe();
            });
        }
      });


    }
  }

}
