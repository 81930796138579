import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SproutFieldBase} from '../../classes/sprout-field-base';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SproutFieldComponent} from '../sprout-field/sprout-field.component';
import {fromEvent, Subscription} from 'rxjs';
import {MatDatepickerInput} from '@angular/material/datepicker';
import {SproutFieldValidator} from '../../enums/sprout-field-validator.enum';
import {SproutFormMetaService} from "../../services/sprout-form-meta.service";

@Component({
  selector: 'app-sprout-date',
  templateUrl: './sprout-date.component.html',
  styleUrls: ['./sprout-date.component.css'],
})
export class SproutDateComponent extends SproutFieldComponent implements OnInit, AfterViewInit {

  innerValue: Date = new Date();

  protected ngZone: NgZone;

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  @Input() field: SproutFieldBase<any>;
  @Input() form: FormGroup;

  eventSubscription: Subscription;
  @ViewChild('sproutDateInput') me: ElementRef;
  @ViewChild(MatDatepickerInput) datepickerInput: MatDatepickerInput<any>;

  labelHasExpression = false;

  protected sanitizer: DomSanitizer;
  protected sproutExpressionService: SproutExpressionService;
  protected localeService: LocaleService;

  events: string[] = [];

  minDate: Date;
  maxDate: Date;

  public i18n: any;

  // minDate = new Date(2000, 0, 1);
  // maxDate = new Date(2020, 0, 1);

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              sproutFormMetaService: SproutFormMetaService,
              localeService: LocaleService,
              ngZone: NgZone) {
    super(sanitizer, sproutExpressionService, sproutFormMetaService, localeService, ngZone);
  }

  ngOnInit(): void {

    // console.log('ngOnInit.field');
    // console.dir(this.field.fieldValidator);

    if (this.field.fieldValidator) {
      switch (this.field.fieldValidator) {
        case SproutFieldValidator.AFTERTODAY:
          this.minDate = new Date();
          break;
        case SproutFieldValidator.BEFORETODAY:
          this.maxDate = new Date();
          break;
      }
    }

    this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
      this.field.labelLocalized = result;
    }).unsubscribe();

    this.i18n = this.localeService.translations;


    console.log('sprout-date.ngOnInit.this.i18n:');
    console.dir(this.i18n);


    this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);

    if (this.field && this.field.requiredExpression && this.field.requiredExpression.length > 0) {
      const variablesRequired: string[] = this.sproutExpressionService.extractVariables(this.field.requiredExpression);
      this.sproutExpressionService.calculateObservable(this.field.requiredExpression, this.form.value).subscribe(result => {
        this.field.required = (/true/i).test(result);
      }).unsubscribe();
      variablesRequired.forEach(variable => {
        const variablesRequiredModel = {};
        if (this.form.get(variable)) {
          this.form.get(variable).valueChanges.subscribe(next => {
            variablesRequiredModel[variable] = next;
            this.sproutExpressionService.calculateObservable(this.field.requiredExpression, variablesRequiredModel).subscribe(result => {
              this.field.required = (/true/i).test(result);
            }).unsubscribe();
          });
        }
      });
    }

    // console.log('ngOnInit.variablesOptions');
    // console.dir(variablesOptions);

    if ((this.field.conditionalExpression && this.field.conditionalExpression.length > 0) ||
      (this.field.expression && this.field.expression.length > 0) ||
      this.labelHasExpression) {
      // if ((this.field.conditionalExpression && this.field.conditionalExpression.length > 0) ||
      //   (this.field.expression && this.field.expression.length > 0) ||
      //   this.labelHasExpression) {

      let variablesConditionalExpression: string[] = null;
      let variablesExpression: string[] = null;
      let variablesLabel: string[] = null;

      if (this.field.conditionalExpression && this.field.conditionalExpression.length > 0) {
        variablesConditionalExpression = this.sproutExpressionService.extractVariables(this.field.conditionalExpression);
      }

      if (this.field.expression && this.field.expression.length > 0) {
        variablesExpression = this.sproutExpressionService.extractVariables(this.field.expression);
      }

      if (this.labelHasExpression) {
        variablesLabel = this.sproutExpressionService.extractVariables(this.field.label);
      }

      if (variablesConditionalExpression && variablesConditionalExpression.length > 0) {
        this.sproutExpressionService.calculateObservable(this.field.conditionalExpression, this.form.value).subscribe(result => {
          this.field.display = (/true/i).test(result);
        });
        const variablesConditionalExpressionModel = {};
        variablesConditionalExpression.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.subscribe(next => {
              variablesConditionalExpressionModel[variable] = next;
              // tslint:disable-next-line:max-line-length
              this.sproutExpressionService.calculateObservable(this.field.conditionalExpression, variablesConditionalExpressionModel).subscribe(result => {
                this.field.display = (/true/i).test(result);
              }).unsubscribe();
            });
          }
        });
      }
      if (variablesExpression && variablesExpression.length > 0) {
        const variablesExpressionModel = {};
        variablesExpression.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.subscribe(next => {
              variablesExpressionModel[variable] = next;
              this.sproutExpressionService.calculateObservable(this.field.expression, variablesExpressionModel).subscribe(result => {
                this.field.value = result;
                this.form.controls[this.field.key].setValue(this.field.value);
              }).unsubscribe();
            });
          }
        });
      }
      if (variablesLabel && variablesLabel.length > 0) {
        const variablesLabelModel = {};
        variablesLabel.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.subscribe(next => {
              variablesLabelModel[variable] = next;
              this.sproutExpressionService.compileObservable(this.field.label, variablesLabelModel).subscribe(result => {
                this.field.labelLocalized = result;
              });
            });
          }
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.eventSubscription = fromEvent(this.me.nativeElement, 'input').subscribe(_ => {
      this.datepickerInput._onInput(this.me.nativeElement.value);
    });
  }

}
