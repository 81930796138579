import {SproutOptionBase} from './sprout-option-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutLikertField extends SproutOptionBase<string> {
  fieldType = 'SproutLikertField';
  controlType = SproutFieldType.likert;
  modelInd = true;
  type: string;
  dataType: SproutDataType;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.dataType = options['dataType'] || SproutDataType.text;
  }
}
