import {Component, Input, OnInit} from '@angular/core';
import {SproutComposite} from '../../classes/sprout-composite';
import {FormGroup} from '@angular/forms';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SproutPage} from '../../classes/sprout-page';

@Component({
  selector: 'app-sprout-page',
  templateUrl: './sprout-page.component.html',
  styleUrls: ['./sprout-page.component.css']
})
export class SproutPageComponent implements OnInit {

  @Input() page: SproutPage;
  @Input() composites: SproutComposite[];
  @Input() form: FormGroup;

  protected sanitizer: DomSanitizer;
  protected sproutExpressionService: SproutExpressionService;
  protected localeService: LocaleService;

  constructor(sanitizer: DomSanitizer,
              sproutExpressionService: SproutExpressionService,
              localeService: LocaleService) {
    this.sanitizer = sanitizer;
    this.sproutExpressionService = sproutExpressionService;
    this.localeService = localeService;
  }

  ngOnInit() {

    if (this.page && this.page.conditionalExpression && this.page.conditionalExpression.length > 0) {
        this.sproutExpressionService.calculateObservable(this.page.conditionalExpression, this.form.value).subscribe(result => {
          this.page.display = (/true/i).test(result);
        }).unsubscribe();
    }

    if (this.page && this.page.style && this.page.style.length > 0) {
      this.page.styleSanitized = this.sanitizer.bypassSecurityTrustStyle(this.page.style);
    }

    if (this.page && this.page.conditionalExpression && this.page.conditionalExpression.length > 0) {
      const variables: string[] = this.sproutExpressionService.extractVariables(this.page.conditionalExpression);
      if (variables && variables.length > 0) {
        const variablesModel = {};
        variables.forEach(variable => {
          if (this.form.get(variable)) {
            this.form.get(variable).valueChanges.subscribe(next => {
              variablesModel[variable] = next;
              this.sproutExpressionService.calculateObservable(this.page.conditionalExpression, variablesModel).subscribe(result => {
                this.page.display = (/true/i).test(result);
              }).unsubscribe();
            });
          }
        });
      }
    }


  }

}
