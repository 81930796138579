import { Directive, HostListener, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Directive({
  selector: '[appMatVerticalStepperScroller]',
})
export class MatVerticalStepperScrollerDirective {
  constructor(private stepper: MatStepper) {}

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {

    // this.stepper.previous();
    // this.stepper.selectedIndex = 0
    //
    // this.stepper._steps.forEach(step => {
    //   console.log('step: ' + step.stepLabel);
    //
    //
    //   step.editable = false;
    //
    // });

    // console.log('MatVerticalStepperScrollerDirective: selectionChanged');
    // console.dir(selection);




    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1);
    const stepElement = document.getElementById(stepId);
    if (stepElement) {
      setTimeout(() => {
        stepElement.scrollIntoView(true);
        // stepElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 50);
    }
  }
}
