import {Injectable} from '@angular/core';
import {SproutFieldBase} from '../classes/sprout-field-base';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutPage} from '../classes/sprout-page';

@Injectable()
export class SproutFormControlService {

  constructor() {
  }

  toFormGroup(pages: SproutPage[]) {
    const group: any = {};

    pages.forEach(page => {
      if (page.composites && page.composites.length > 0) {
        page.composites.forEach(composite => {
          if (composite.fields && composite.fields.length > 0) {
            composite.fields.forEach(field => {
              if (field.modelInd === true) {
                if (field.controlType === SproutFieldType.likert) {
                  if (field.questions && field.questions.length > 0) {
                    field.questions.forEach(question => {
                      group[field.key + question.key] = field.required ? new FormControl(field.value || '', Validators.required)
                        : new FormControl(field.value || '');
                    });
                  }
                } else if (field.controlType === SproutFieldType.row) {
                  if (field.fields && field.fields.length > 0) {
                    field.fields.forEach(fieldRow => {
                      if (fieldRow.modelInd === true) {
                        group[fieldRow.key] = fieldRow.required ? new FormControl(fieldRow.value || '', Validators.required)
                          : new FormControl(fieldRow.value || '');
                      }
                    });
                  }
                } else if (field.controlType === SproutFieldType.checkbox) {
                  let value = false;
                  if (field.value) value = field.value;
                  group[field.key] = field.required ? new FormControl(value, Validators.required)
                    : new FormControl(value);
                } else {
                  group[field.key] = field.required ? new FormControl(field.value || '', Validators.required)
                    : new FormControl(field.value || '');
                }
              }
            });
          }
        });
      }
    });

    console.log('toFormGroup.group: ' + group);
    console.dir(group);
    return new FormGroup(group);
  }

  /*
    toFormGroup(fields: SproutFieldBase<any>[] ) {
      const group: any = {};

      fields.forEach(field => {
        if (field.modelInd === true) {
          if (field.controlType !== SproutFieldType.likert) {
            group[field.key] = field.required ? new FormControl(field.value || '', Validators.required)
              : new FormControl(field.value || '');
          } else {
            if (field.questions && field.questions.length > 0) {
              field.questions.forEach(question => {
                group[field.key + question.key] = field.required ? new FormControl(field.value || '', Validators.required)
                  : new FormControl(field.value || '');
              });
            }
          }
        }
      });

      // console.log('toFormGroup.group: ' + group);
      // console.dir(group);
      return new FormGroup(group);
    }
  */
}
