import {SproutComposite} from './sprout-composite';
import {SafeStyle} from '@angular/platform-browser';

export class SproutPage {
  public name: string;
  label: any;
  labelLocalized: string;
  public composites: SproutComposite[];

  conditionalExpression: string;
  expression: string;
  class: string;
  style: string;
  safe: boolean;

  styleSanitized: SafeStyle;

  display = true;

  constructor(name: string, label: any, composites: SproutComposite[], options: {
    conditionalExpression?: string,
    expression?: string,
    class?: string,
    style?: string,
    safe?: boolean,
  } = {}) {
    this.name = name;
    this.label = label;
    this.composites = composites;

    if (options) {
      this.conditionalExpression = options.conditionalExpression || null;
      this.expression = options.expression || null;
      this.class = options.class || null;
      this.style = options.style || null;
      this.safe = options.safe || false;
    }
  }
}
