import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import { Bootstrapper } from './bootstraper';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


// platformBrowserDynamic().bootstrapModule(AppModule, { ngZone: 'noop' })
//   .catch(err => console.error(err));




// const bootstrapApp = function(): void {
//   platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .then(() => {})
//     .catch(err => console.error(err));
// };
//
// const bootstrapper = new Bootstrapper(bootstrapApp);
// bootstrapper.startup();
