import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LocaleService} from "./services/locale.service";
import {SproutFormMetaService} from "./services/sprout-form-meta.service";
import {SproutFormDataService} from "./services/sprout-form-data.service";
import {FormTO} from "./classes/form-to";
import {Locale} from "./interfaces/locale";
import {SproutForm} from "./interfaces/sprout-form";
import {Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import {InstanceTO} from "./classes/instance-to";

@Component({
  selector: 'sprout-forms',
  templateUrl: './sprout-forms-library.component.html',
  providers: [ LocaleService, SproutFormMetaService, SproutFormDataService ],
  styleUrls: ['./sprout-forms-library.component.css']
})
export class SproutFormsLibraryComponent implements OnInit, OnChanges {

  @Input()
  baseUrl = 'http://localhost:8084';

  @Input()
  public model: FormTO;

  @Input('locale')
  public localeCode: string;

  @Input()
  public publicationKey: string;

  @Input()
  public formKey: string;

  @Input()
  public formValueInput: string;

  @Input()
  public authKey: string;

  @Input()
  public instanceKey: string;

  @Output()
  complete = new EventEmitter();

  @Output()
  status = new EventEmitter();

  @Output()
  formValue = new EventEmitter();

  locale: Locale;
  locales: Locale[];
  // form: SproutForm;
  sproutForm: SproutForm;

  subscription: Subscription;

  localePickerForm: FormGroup;

  constructor(
    public sproutFormDataService: SproutFormDataService,
    private localeService: LocaleService,
    private sproutFormMetaService: SproutFormMetaService) {

    console.log('SproutFormsLibraryComponent.constructor');

  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges.changes');
    // console.dir(this.model);
    // console.dir(changes);
    // console.log('ngOnChanges.this.publicationKey789: ' + this.publicationKey);
    // this.initializeForm();
  }

  initializeForm() {

    this.sproutFormDataService.baseUrl = this.baseUrl;

    // console.log('initializeForm.this.sproutFormDataService.baseUrl:');
    // console.dir(this.sproutFormDataService.baseUrl);

    const instanceTO = new InstanceTO();

    if (this.authKey) {
      instanceTO.authKey = this.authKey;
    }

    console.log('initializeForm.this.instanceKey: ' + this.instanceKey);

    if (this.model && this.model.model) {

    } else if (this.instanceKey) {
      instanceTO.instanceKey = this.instanceKey;
      this.sproutFormDataService.instance = instanceTO;
    } else if (this.publicationKey) {
      instanceTO.publicationKey = this.publicationKey;
      this.sproutFormDataService.instance = instanceTO;
    } else if (this.formKey) {
      this.sproutFormDataService.getFormModelFromKey(this.formKey).subscribe(model => {
        const formTO: FormTO = new FormTO();
        formTO.model = model as string;
        if (this.formValueInput) formTO.value = this.formValueInput;
        this.model = formTO;
      });

    }

    this.localeService.showDropdown = false;

    if (this.localeCode) {
      const locales = this.getLocales();
      if (locales && locales.length > 0) {
        for (let localeTmp of locales) {
          if (localeTmp.code === this.localeCode) {
            this.localeService.locale = localeTmp;
            this.localeService.showDropdown = false;
          }
        }
      }
    }

  }

  ngOnInit(): void {
    console.log('SproutFormsLibraryComponent.ngOnInit');

    const statusChange = this.sproutFormMetaService.status$.subscribe($event => {
      // console.log('sproutFormMetaService status changed: $event:');
      // console.dir($event);
      this.status.emit($event);
      if ($event == 'complete') {
        statusChange.unsubscribe();
      }
    });

    const formValueChange = this.sproutFormMetaService.formValue$.subscribe($event => {
      this.formValue.emit($event);
    });

    setTimeout(() => {
      // console.log('ngOnInit.this.publicationKey789: ' + this.publicationKey);
      this.initializeForm();
    }, 1000);

  }

  changeLocal() {
    // console.log('changeLocal: ' + this.locale);
    this.localeService.locale = this.locale;
  }

  getLocales() {
    return this.localeService.locales;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
