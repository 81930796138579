import {Component, Input, NgZone, OnInit} from '@angular/core';
import {SproutFieldBase} from '../../classes/sprout-field-base';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, pairwise, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-sprout-label',
  templateUrl: './sprout-label.component.html',
  styleUrls: ['./sprout-label.component.css']
})
export class SproutLabelComponent implements OnInit {

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  @Input() field: SproutFieldBase<any>;
  @Input() form: FormGroup;

  labelHasExpression = false;
  safeType: string;

  labelLocalized$: Observable<String>;

  constructor(private sproutExpressionService: SproutExpressionService,
              private localeService: LocaleService,
              private ngZone: NgZone
          ) {}

  ngOnInit(): void {

    const variables: string[] = this.sproutExpressionService.extractVariables(JSON.stringify(this.field.label));
    let localizationInitialized = false;

    this.localeService._locale$.subscribe(locale => {
      if (localizationInitialized) {
        if (variables && variables.length > 0) {
          this.form.get(variables[0]).updateValueAndValidity({ onlySelf: true, emitEvent: true });
        } else {
          this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
            this.field.labelLocalized = result;
            this.labelLocalized$ = undefined;
          }).unsubscribe();
        }
      } else {
        localizationInitialized = true;
      }
    });

    if (this.field.safe === true) {
      this.safeType = 'html';
    }

    if (variables && variables.length > 0) {
      const labelModel = {};
      variables.forEach(variable => {
        if (this.form.get(variable)) {

          // console.log(this.field.name + " watching variable ==> " + variable);

          this.form.controls[variable].valueChanges.pipe(
            debounceTime(100),
            startWith(''),
            distinctUntilChanged()
          ).subscribe(next => {
            // console.log(this.field.name + " would like to regenerate label due to change in variable, " + variable);
            labelModel[variable] = next;

            // if (this.form.value && this.form.value[variable] && this.form.value[variable] !== next) {
            //   console.log(this.field.name + ': Value does not match: ' + this.form.value[variable] + ' !== ' + next);
            //   this.form.value[variable] = next;
            // }

            // this.labelLocalized$ = this.sproutExpressionService.compileObservable(this.field.label, labelModel);
            this.labelLocalized$ = this.sproutExpressionService.compileObservable(this.field.label, this.form.value);
          });

            // this.labelLocalized$ = this.form.controls[variable].valueChanges
            // .pipe(
            //   debounceTime(100),
            //   distinctUntilChanged(),
            //   startWith(''),
            //   map(next => {
            //
            //     console.log(this.field.name + " regenerating label due to change in variable, " + variable);
            //
            //
            //     labelModel[variable] = next;
            //     return this.sproutExpressionService.compileSync(this.field.label, this.form.value);
            //   })
            // );
          // this.labelLocalized$ = this.form.get(variable).valueChanges
          //   .pipe(
          //     debounceTime(100),
          //     // distinctUntilChanged(),
          //     startWith(''),
          //     map(next => {
          //
          //       console.log(this.field.name + " regenerating label due to change in variable, " + variable);
          //
          //
          //       labelModel[variable] = next;
          //       return this.sproutExpressionService.compileSync(this.field.label, this.form.value);
          //     })
          //   );
        }
      });
      this.labelLocalized$ = this.sproutExpressionService.compileObservable(this.field.label, this.form.value);

    } else {
      this.sproutExpressionService.compileObservable(this.field.label, this.form.value).subscribe(result => {
        this.labelLocalized$ = undefined;
        this.field.labelLocalized = result;
      }).unsubscribe();

    }
  }

}
