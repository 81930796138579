import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

import {Locale} from '../../interfaces/locale';
import {SproutForm} from '../../interfaces/sprout-form';
import {SproutFormDataService} from '../../services/sprout-form-data.service';
import {LocaleService} from '../../services/locale.service';
import {SproutFormMetaService} from '../../services/sprout-form-meta.service';
import {MetaInfo} from '../../interfaces/meta-info';
import {SproutPage} from '../../classes/sprout-page';
import {FormTO} from '../../classes/form-to';
import {InstanceTO} from "../../classes/instance-to";
import {StatusTO} from "../../classes/status-to";

@Component({
  selector: 'form-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css'],
})
export class ViewerComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public model: FormTO;

  private showForm = true;
  private showCounter: number;

  locale: Locale;
  locales: Locale[];
  // form: SproutForm;
  sproutForm: SproutForm;
  referenceInd: boolean;

  subscription: Subscription;

  localePickerForm: FormGroup;

  constructor(
    private sproutFormDataService: SproutFormDataService,
    public localeService: LocaleService,
    private sproutFormMetaService: SproutFormMetaService,
  ) {

    // this.referenceInd = this.route.snapshot.queryParams.ref;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('viewer.ngOnChanges');
    // console.dir(this.model);
    // this.drawForm();  // not sure why I added this originally but it causes the page labels to disappear.
  }

  ngOnInit(): void {

    // this.referenceInd = true;

    this.showCounter = 0;
    this.drawForm();

    // this.sproutForm = this.sproutFormDataService.getSproutForm();
    // this.sproutFormMetaService.form = this.sproutForm;
    //
    this.locales = this.localeService.locales;
    this.locale = this.locales[0];
    //
    this.localePickerForm = new FormGroup({
      locale: new FormControl(this.locale),
    });

    this.localePickerForm.controls['locale'].valueChanges.subscribe((locale: Locale) => {
      this.localeService.locale = locale;
    });

    // this.subscription = this.sproutFormMetaService._sproutFormMetaService$.subscribe(form => {
    //   this.form = form;
    // });

  }

  drawForm() {

    // console.log('%c Oh my heavens! ', 'background: #222; color: #bada55');
    // console.log('%c drawForm.this.sproutForm:', 'background: #222; color: #bada55');
    // console.dir(this.sproutForm);
    // console.log('drawForm.this.showForm: ' + this.showForm);
    // console.log('drawForm.this.showCounter: ' + this.showCounter);

    this.destroyForm();
    // if (!this.sproutForm) {


    // if (this.showForm) {

      // console.log('drawForm showing form...');

    let instance = this.sproutFormDataService.instance;

    // console.log('drawForm.instance:');
    // console.dir(instance);

    if (instance) {
      this.sproutFormDataService.getSproutForm(instance).subscribe(instanceRaw => {
        // console.log('formModel:');
        // console.dir(formModel);

        const instanceTO = instanceRaw as InstanceTO;

        const metaInfo: MetaInfo = {formName: instanceTO.name};

        // console.log('drawForm.instanceTO:');
        // console.dir(instanceTO);

        if (!instance.instanceKey && instanceTO.instanceKey) {
          console.log('viewer.drawform .5');

          instance.instanceKey = instanceTO.instanceKey;
          instance.instanceId = instanceTO.instanceId;
          // this.sproutFormDataService.instance = instance;
        }

        this.sproutFormDataService.instance = instanceTO;

        // console.log('viewer.drawform 1');
        // this.sproutForm = {
        //   meta: metaInfo,
        //   model: []
        // };
        // this.sproutForm = {
        //   meta: metaInfo,
        //   model: instanceTO.schema as SproutPage[]
        // };

        // console.log('viewer.drawform 2');

        this.sproutForm = {
          meta: metaInfo,
          model: JSON.parse(instanceTO.schema) as SproutPage[],
          value: undefined
        };

        // console.log('viewer.drawform 3');

        // if (instanceTO.model !== undefined && instanceTO.model.length > 0) {
        if (instanceTO.model && instanceTO.model.length > 0) {
          this.sproutForm.value = JSON.parse(instanceTO.model);
        }

        // console.log('viewer.drawform 4');

        // console.error("Not an error: Look at sproutForm Object.");
        // console.log("instanceTO.schema: (should be object not a string): " + (JSON.parse(instanceTO.schema) as SproutPage[]));
        // console.dir(instanceTO.schema);

        this.sproutFormMetaService.form = this.sproutForm;

        // console.log('viewer.drawform 5');

      }, error => {
        // console.log('error');
        // console.dir(error);
        this.sproutFormMetaService.setStatus(new StatusTO('error', error.status, 'Access denied'));
      });

    } else {
      if (this.model && this.model.model) {
        // console.log('viewer.component.ts.ngOnInit.this.model.formModel:');
        // console.dir(JSON.parse(this.model.model));

        const metaInfo: MetaInfo = {formName: 'Sample Sprout Form'};

        this.sproutForm = {
          meta: metaInfo,
          model: [],
          value: undefined
        };

        if (typeof this.model.model === 'object') {
          this.sproutForm.model = this.model.model as SproutPage[];
          // console.log(JSON.stringify(this.model.model));
        } else {
          this.sproutForm.model = JSON.parse(this.model.model) as SproutPage[];
        }

        if (this.model && this.model.value) {
          this.sproutForm.value = JSON.parse(this.model.value);
        }

        // console.log('%c drawForm: Look at sproutForm Object!!!', 'background: #222; color: #33ff22');
        // console.dir(this.sproutForm);

        this.sproutFormMetaService.form = undefined;

        this.sproutFormMetaService.form = this.sproutForm;

      } else {
        this.sproutFormDataService.getSproutFormReference().subscribe(formModel => {
          // console.log('formModel:');
          // console.dir(formModel);

          const metaInfo: MetaInfo = {formName: 'Sample Sprout Form'};

          // this.sproutForm = {
          //   meta: metaInfo,
          //   model: []
          // };
          this.sproutForm = {
            meta: metaInfo,
            model: formModel as SproutPage[],
            value: undefined
          };

          // console.error("Not an error: Look at sproutForm Object.");
          // console.dir(this.sproutForm);

          if (this.model && this.model.value) {
            this.sproutForm.value = JSON.parse(this.model.value);
          }

          this.sproutFormMetaService.form = this.sproutForm;

        });
      }

    }

    console.log('viewer.drawform done');


    // }
    //
    // this.showForm = !this.showForm;
    // this.showCounter++;

    // }

  }

  changeLocal() {
    this.localeService.locale = this.locale;
  }

  getLocales() {
    return this.localeService.locales;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private destroyForm() {
    this.sproutForm = undefined;
    const metaInfo: MetaInfo = {formName: 'Building form...'};

    this.sproutForm = {
      meta: metaInfo,
      model: [],
      value: undefined
    };

    this.sproutFormMetaService.form = this.sproutForm;

  }
}
