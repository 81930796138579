import {Component, Input, OnInit} from '@angular/core';
import {SproutFieldType} from '../../enums/sprout-field-type.enum';
import {SproutFieldBase} from '../../classes/sprout-field-base';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {SproutFieldOption} from '../../interfaces/sprout-field-option';

@Component({
  selector: 'app-sprout-slider-poll-label',
  templateUrl: './sprout-slider-poll-label.component.html',
  styleUrls: ['./sprout-slider-poll-label.component.css']
})
export class SproutSliderPollLabelComponent implements OnInit {

  SproutFieldType: typeof SproutFieldType = SproutFieldType;

  @Input() poll: string;
  @Input() field: SproutFieldBase<any>;

  option: SproutFieldOption;

  labelHasExpression = false;

  constructor(private sproutExpressionService: SproutExpressionService, private localeService: LocaleService) {

  }

  ngOnInit(): void {
    // console.log('SproutSliderPollLabelComponent.ngOnInit');
    // console.dir(this.poll);
    // console.dir(this.field);

    if (this.field && this.field.options) {
      if (this.poll === 'min') {
        this.option = this.field.options[0];
      } else {
        this.option = this.field.options[this.field.options.length - 1];
      }
      // console.dir(this.option );
    }

/*
    this.field.labelLocalized = this.sproutExpressionService.compile(this.field.label, this.form.value);

    this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);

    this.localeService._locale$.subscribe(locale => {
      this.field.labelLocalized = this.sproutExpressionService.compile(this.field.label, this.form.value);
      this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);
    });


    if (this.labelHasExpression) {

      this.form.valueChanges.subscribe(model => {
        if (this.labelHasExpression) {
          const newLabelLocalized = this.sproutExpressionService.compile(this.field.label, this.form.value);
          if (this.field.labelLocalized !== newLabelLocalized) {
            this.field.labelLocalized = newLabelLocalized;
          }
        }

      });
    }
*/
  }

}
