export enum SproutFieldValidator {
  DATE = 'DATE',
  INTEGER = 'INTEGER',
  PHONE = 'PHONE',
  ZIP = 'ZIP',
  CALENDAR = 'CALENDAR',
  YEAR = 'YEAR',
  DATERANGE = 'DATERANGE',
  BEFORETODAY = 'BEFORETODAY',
  AFTERTODAY = 'AFTERTODAY',
  ANYDATE = 'ANYDATE',
  FLOAT = 'FLOAT',
  EMAIL = 'EMAIL',
  URL = 'URL',
  UNIQUE = 'UNIQUE',
}
