export class FormTO {
  id: number;
  publicationName: string;
  publicationCount: number;
  formName: string;
  formKey: string;
  owner: string;
  model: string;
  value: string;
  active: boolean;
  activityDate: Date;
}
