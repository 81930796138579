export class SproutControlItem {
  label: any;
  labelLocalized: string;

  constructor(label: any) {
    this.label = label;
  }

  public toString = () : string => {
    return this.labelLocalized;
  }

}
