export class InstanceTO {
  instanceId: number;
  name: string;
  publicationKey: string;
  instanceKey: string;
  authKey: string;
  schema: string;
  model: string;
  command: string;
  location: string;
}
