import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';
import {SproutOptionBase} from './sprout-option-base';

export class SproutRadioField extends SproutOptionBase<string> {
  fieldType = 'SproutRadioField';
  controlType = SproutFieldType.radio;
  modelInd = true;
  type: string;
  dataType: SproutDataType;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.dataType = options['dataType'] || SproutDataType.text;
  }
}
