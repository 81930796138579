export enum SproutFieldType {
  text = 'text',
  date = 'date',
  textarea = 'textarea',
  radio = 'radio',
  select = 'select',
  checkbox = 'checkbox',
  slider = 'slider',
  sliderToggle = 'sliderToggle',
  likert = 'likert',
  div = 'div',
  span = 'span',
  heading = 'heading',
  label = 'label',
  calculation = 'calculation',
  page = 'page',
  composite = 'composite',
  row = 'row',
  blank = 'blank',
}
