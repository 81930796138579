import {Compiler, Injectable} from '@angular/core';
import {Observable, of } from 'rxjs';
import { DateTime } from 'luxon';
import {LocaleService} from './locale.service';
import {SproutFormMetaService} from './sprout-form-meta.service';
import {SproutField} from '../interfaces/sprout-field';
import {SproutDataType} from '../enums/sprout-data-type.enum';

@Injectable()
export class SproutExpressionService {

  private fieldMap: Map<string, SproutField>;
  private readonly expressionCache: Map<string, string>;

  private regexSingleQuote: RegExp = new RegExp('\'', 'g');

  private formValue: any;

  constructor(private localeService: LocaleService, private sproutFormMetaService: SproutFormMetaService, private compiler: Compiler) {
    this.fieldMap = this.sproutFormMetaService.fieldMap;
    this.expressionCache = new Map<string, string>();

    this.sproutFormMetaService.formValue$.subscribe(formValue => {
      this.formValue = formValue;
    });

  }

  compileSync(expressionRaw: any, modelRaw: any) {
    if (expressionRaw) {
      const expression: string = this.localeService.localize(expressionRaw);
      if (this.isCalculation(expression)) {
        return this.calculateSync(expression, modelRaw);
      } else {
        return expression;
      }
    }
  }

  compileObservable(expressionRaw: any, modelRaw: any): Observable<string> {
    if (expressionRaw) {
      const expression: string = this.localeService.localize(expressionRaw);
      if (this.isCalculation(expression)) {
        return this.calculateObservable(expression, modelRaw);
      } else {
        return of(expression);
      }
    } else {
      return of(expressionRaw);
    }
  }

  hasVariables(expressionRaw: string) {
    const expression: string = this.localeService.localize(expressionRaw);
    if (expression && expression.length > 0) {
      const variables: string[] = this.extractVariables(expression);
      return (variables && variables.length > 0);
    }
    return false;
  }

  calculateSync(expressionRaw: string, modelRaw: any) {
    // console.log('calculateSync');
    return this.calculate(expressionRaw, modelRaw);
  }

  calculateObservable(expressionRaw: string, modelRaw: any): Observable<string> {
    // console.log('calculateObservable');
    return of(this.calculate(expressionRaw, modelRaw));
  }

  private calculate(expressionRaw: string, modelRaw: any): string {

    // console.log('calculate');
    // console.log('calculate.expressionRaw: ' + expressionRaw);

    // let watchMe = false;

    // if (expressionRaw.indexOf('pt_gender') > 0) {
    // if (expressionRaw.indexOf('pt_gender') > 0 && expressionRaw.indexOf('she') > 0) {
    // if (expressionRaw === 'concat(([pt_gender] = "M" ? "he" : "") + ([pt_gender] = "F" ? "she" : ""))') {
    //   watchMe = true;
    //   console.log('calculate.watchMe: ' + expressionRaw);
    // }

    let expression = null;

    if (this.expressionCache && this.expressionCache.get(expressionRaw)) {
      expression = this.expressionCache.get(expressionRaw)
      // console.log('calculate...using cache');
    } else {
      const variables: string[] = this.extractVariables(expressionRaw);

      // console.log('expressionRaw: ' + expressionRaw);

      // tslint:disable-next-line:no-shadowed-variable
      let expression = expressionRaw.replace(/\[/g, 'NORMALIZE(model[\'').replace(/]/g, '\'])');

      // let labelIndicator = false;

      if (expressionRaw.startsWith("CALC('") && expressionRaw.indexOf("' + [") >= 0) {
        // labelIndicator = true;
        expression = expressionRaw.replace(/' \+ \[/g, '\' + NORMALIZE(model[\'').replace(/] \+ '/g, '\']) + \'');
      }

      // expression = expressionRaw.replace(/\[/g, 'NORMALIZE(model[\'').replace(/]/g, '\'])');
      // expression = expressionRaw.replace(/\${\[/g, '${NORMALIZE(model[\'').replace(/]}/g, '\'])}');


      // console.log('%c expression old: ' + expressionOrig, 'background: #5f3a3d; color: #bada55');
      // console.log('%c expression new: ' + expression, 'background: #222; color: #bada55');

      for (const variable of variables) {
        const field: SproutField = this.fieldMap.get(variable);
        if (field && field.dataType === SproutDataType.number) {
          const regex = new RegExp('model\\\[\'' + variable + '\']', 'g');
          expression = expression.replace(regex, 'parseFloat(model[\'' + variable + '\'])');
        }
      }
      this.expressionCache.set(expressionRaw, expression);
    }

    let modelString: string;

    try {

      if (typeof modelRaw === 'object') {
        modelString = JSON.stringify(modelRaw);
      } else {
        modelString = modelRaw.replace(this.regexSingleQuote, '\\\'');
      }

      // if (expression && expression != null && expression.indexOf("99") > 0) {
      //   console.log('%c calculate 99!!!', 'background: #222; color: #bada55');
      // }
        // console.log('calculate ERROR');
      // console.log('modelString ' + modelString);
        // console.dir(modelRaw);

        // console.log('calculate: ' + expression);
        // console.log('calculateWithSingleQuoteReplace: ' + modelString.replace(this.regexSingleQuote, '\\\''));
        // console.dir(modelString);
        // console.dir(this.formValue);
      // }



      let retVal = this.evaluationFunction(modelString, expression, DateTime);
      // let retVal = this.evaluationFunction(modelString.replace(this.regexSingleQuote, '\\\''), expression, DateTime);
      // console.log('%c result: ' + retVal, 'background: #222; color: #bada55');
      return retVal;
    } catch (e) {
      // console.log('  ****** Calculation ERROR ******');
      // console.log('calculate: ' + expression);
      // console.log('calculateWithSingleQuoteReplace: ' + modelString.replace(this.regexSingleQuote, '\\\''));
      // console.dir(modelString);
      // console.dir(this.formValue);

      console.error(e);
      console.log('modelString ' + modelString);
      console.error('failed expression: ' + expression);
    }
  }

  public extractVariables(expressionRaw): string[] {

    const variables: string[] = [];

    if (expressionRaw && expressionRaw.length > 0) {
      // return expressionRaw.match(/\[(\w+)\]/g);
      const matches: string[] = expressionRaw.match(/[^[\]]+(?=])/g);

      if (matches && matches.length > 0) {
        for (const variable of matches) {
          variable.toLowerCase();
          let exists = false;
          for (const variableTest of variables) {
            if (variableTest === variable.toLowerCase()) { exists = true; }
          }
          if (!exists) { variables.push(variable.toLowerCase()); }
        }
      }
    }

    return variables;
  }
  // private extractVariables(expressionRaw): Set<string> {
  //
  //   const variables: Set<string> = new Set<string>();
  //
  //   // return expressionRaw.match(/\[(\w+)\]/g);
  //   const matches: string[] = expressionRaw.match(/[^[\]]+(?=])/g);
  //
  //   for (const variable in matches) {
  //     variables.add(variable);
  //   }
  //
  //   return variables;
  // }

  regex = /\${\[.*?]}/g;

  private isCalculation(expressionRaw) {
    if (expressionRaw) {
      return expressionRaw.toLowerCase().startsWith('calc(');
      // return expressionRaw.toLowerCase().startsWith('calc(') || expressionRaw.match(this.regex);
    }
    return false;
  }

  private calculationTemplate: string = `
      var NORMALIZE = function(expression) {
        // return (!expression || typeof expression === 'undefined') ? '' : expression;
        if (typeof expression === 'undefined') return '';
        if (typeof expression === 'number') return expression;
        return (!expression) ? '' : expression;
      }
      var CALC = function(expression) {
        return expression;
      }
      var CONCAT = function(expression) {
        return expression;
      }
      var today = function() {
        return DateTime.local();
      }
      function isDateInstance(obj) {
        return obj instanceof DateTime;
      }
      function convertSproutTimeUnits(sproutUnit) {
        if (sproutUnit && sproutUnit.length > 0) {
          switch (sproutUnit) {
            case 'y':
              return 'years';
              break;
            case 'm':
              return 'months';
              break;
            case 'd':
              return 'days';
              break;
            default:
              return sproutUnit;
              break;
              }
        }
      }
      function convertDateDiffTimeToString(sproutUnit, dateDiffTime) {
        if (dateDiffTime && sproutUnit) {
          // if (Object.prototype.toString.call(dateDiffTime) !== '[object Object]') {
            switch (sproutUnit) {
              case 'y':
                return Math.trunc(dateDiffTime.years);
                break;
              case 'm':
                return Math.trunc(dateDiffTime.months);
                break;
              case 'd':
                return Math.trunc(dateDiffTime.days);
                break;
              default:
                return JSON.stringify(dateDiffTime);
                break;
                }
          }
        // }
      }
      var datedif = function(date1, date2, unit) {
        // console.log('calculate.date1.type: ' + Object.prototype.toString.call(date1));
        // console.log('calculate.date2.type: ' + Object.prototype.toString.call(date2));
        if (date1 && date1.length > 0) {
          if (Object.prototype.toString.call(date1) !== '[object Object]') {
            date1 = new DateTime.fromISO(date1);
          }
        }
        if (date2 && date2.length > 0) {
          if (Object.prototype.toString.call(date2) !== '[object Object]') {
            date2 = new DateTime.fromISO(date2);
          }
        }
        //
        // console.log('calculate.isDateInstance(date1): ' + isDateInstance(date1));
        // console.log('calculate.isDateInstance(date2): ' + isDateInstance(date2));
        //
        // return date2.diff(date1, 'years');
        if (isDateInstance(date1) && isDateInstance(date2)) {
          return convertDateDiffTimeToString(unit, date2.diff(date1, convertSproutTimeUnits(unit)));
        } else {
          return null;
        }
      }
      var model = JSON.parse(modelString);
      return eval(expressionRaw);
     `;

      private evaluationFunction: Function = Function('modelString', 'expressionRaw', 'DateTime', this.calculationTemplate);


}
