<div [formGroup]="form">
  <!--<label [attr.for]="field.key">{{field.labelLocalized}}</label>-->

  <div [ngSwitch]="field.controlType" class="sprout-field">
    <div *ngSwitchCase="SproutFieldType.text">
      <mat-form-field class="sprout-full-width" *ngIf="field.display !== false">
        <input matInput
               class="sproutFieldSelector"
               #field_{{form.key}}
               (focus)="onRegisterFocus(field.key)"
               [formControlName]="field.key"
               [id]="field.key"
               [type]="field.dataType"
               [placeholder]="field.labelLocalized"
               [required]="isRequired()"
        >
<!--        <mat-hint>Errors appear instantly!</mat-hint>-->
        <mat-error>
<!--        <mat-error *ngIf="form.controls[field.key].hasError('required')">-->
          {{i18n['requiredErrorText']}}
        </mat-error>
      </mat-form-field>

    </div>
    <div *ngSwitchCase="SproutFieldType.date">
      <app-sprout-date [form]="form" [field]="field"
                       #field_{{form.key}}
                       (focus)="onRegisterFocus(field.key)"
                       [id]="field.key"
      ></app-sprout-date>
    </div>
    <div *ngSwitchCase="SproutFieldType.calculation">
      <mat-form-field class="sprout-full-width" *ngIf="field.display !== false">
        <input matInput
               #field_{{form.key}}
               (focus)="onRegisterFocus(field.key)"
               [formControlName]="field.key"
               [id]="field.key"
               [type]="field.dataType"
               [value]="field.value"
               [placeholder]="field.labelLocalized"
               [required]="isRequired()"
               readonly="readonly"
        >
        <mat-error *ngIf="form.controls[field.key].hasError('required')">
          {{i18n['requiredErrorText']}}
        </mat-error>
      </mat-form-field>

    </div>
    <div *ngSwitchCase="SproutFieldType.heading" class="sprout-field">
      <div *ngIf="field.display !== false">
        <h1><app-sprout-label [form]="form" [field]="field"></app-sprout-label></h1>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.label" class="sprout-field">
      <div *ngIf="field.display !== false">
        <h2><app-sprout-label [form]="form" [field]="field"></app-sprout-label></h2>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.div" class="sprout-field">
      <div *ngIf="field.display !== false">
        <div [style]="field.styleSanitized" [class]="field.class"><app-sprout-label [form]="form" [field]="field"></app-sprout-label></div>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.span" class="sprout-field">
      <div *ngIf="field.display !== false">
        <span [style]="field.styleSanitized"><app-sprout-label [form]="form" [field]="field"></app-sprout-label></span>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.blank" class="sprout-field">
      <div *ngIf="field.display !== false">&nbsp;</div>
    </div>

    <div *ngSwitchCase="SproutFieldType.textarea">
      <mat-form-field class="sprout-full-width" *ngIf="field.display !== false">
                <textarea matInput
                          #field_{{form.key}}
                          (focus)="onRegisterFocus(field.key)"
                          [formControlName]="field.key"
                          [id]="field.key"
                          [placeholder]="field.labelLocalized"
                          [required]="isRequired()"
                >
                </textarea>
        <mat-error *ngIf="form.controls[field.key].hasError('required')">
          {{i18n['requiredErrorText']}}
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="SproutFieldType.radio">

      <div *ngIf="field.display !== false" [class]="form.controls[field.key].hasError('required') && pageDirty ? 'error' : ''">
        <h3><app-sprout-label [form]="form" [field]="field"></app-sprout-label><span *ngIf="isRequired()" [class]="form.controls[field.key].hasError('required') && pageDirty ? 'error' : 'muted'"> *</span></h3>
        <mat-radio-group class="sprout-full-width sprout-radio-group"
                         #field_{{form.key}}
                         (change)="onRegisterFocus(field.key)"
                         [formControlName]="field.key"
                         [id]="field.key"
                         [required]="isRequired()"
                         >
          <mat-radio-button class="vertical-radio" *ngFor="let option of field.options" [value]="option.key">
            <span *ngIf="option.labelLocalized" [innerHtml]="option.labelLocalized | safe: safeType"></span>
            <span *ngIf="!option.labelLocalized" [innerHtml]="option.labelLocalized | safe: safeType"></span>
<!--            {{option.labelLocalized}}-->
          </mat-radio-button>

          <mat-error *ngIf="form.controls[field.key].hasError('required') && pageDirty"
            style="font-size: 75%; margin-top: 15px;"
          >
            {{i18n['requiredErrorText']}}
          </mat-error>

<!--          <h4>form.controls[field.key].invalid: {{form.controls[field.key].invalid}}</h4>-->
<!--          <h4>form.dirty: {{form.dirty}}</h4>-->
<!--          <div class="errors" *ngIf="form.controls[field.key].invalid && form.dirty">-->
<!--            <div >Must select an option</div>-->
<!--            <mat-error *ngIf="form.controls[field.key].invalid && form.dirty">Must select an option</mat-error>-->
<!--            <mat-error *ifError="form.controls[field.key]">Must select an option</mat-error>-->
<!--          </div>-->
        </mat-radio-group>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.select">
      <mat-form-field class="sprout-full-width" *ngIf="field.display !== false">
        <mat-select
          [id]="field.key"
          #field_{{form.key}}
          (change)="onRegisterFocus(field.key)"
          [placeholder]="field.labelLocalized"
          [formControlName]="field.key"
          [required]="isRequired()"
        >
          <mat-option *ngFor="let option of field.options" [value]="option.key">
            {{option.labelLocalized}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls[field.key].hasError('required')">
          {{i18n['requiredErrorText']}}
        </mat-error>
      </mat-form-field>
    </div>


    <div *ngSwitchCase="SproutFieldType.checkbox">
      <mat-checkbox
        #field_{{form.key}}
        (change)="onRegisterFocus(field.key)"
        *ngIf="field.display !== false"
        [id]="field.key"
        [formControlName]="field.key"
        [labelPosition]="'left'"
      >
        <app-sprout-label [form]="form" [field]="field"></app-sprout-label>
      </mat-checkbox>
    </div>


<!--    <div *ngSwitchCase="SproutFieldType.checkbox">-->
<!--      <mat-checkbox-->
<!--        [formControlName]="field.key"-->
<!--        [labelPosition]="'left'"-->
<!--      >-->
<!--        <app-sprout-label [form]="form" [field]="field"></app-sprout-label>-->
<!--      </mat-checkbox>-->
<!--    </div>-->

    <div *ngSwitchCase="SproutFieldType.sliderToggle">
      <app-sprout-label [form]="form" [field]="field"></app-sprout-label>
      <mat-slide-toggle *ngIf="field.display !== false"
                        [formControlName]="field.key"
                        [id]="field.key"
                        #field_{{form.key}}
                        (focus)="onRegisterFocus(field.key)"
      >
      </mat-slide-toggle>

    </div>


    <div *ngSwitchCase="SproutFieldType.slider">
      <div *ngIf="field.display !== false">
        <h4>
          <app-sprout-label [form]="form" [field]="field"></app-sprout-label>
        </h4>

        <div>
          <span class="text-small sprout-likert-label-pole">
            <app-sprout-slider-poll-label [poll]="'min'" [field]="field"></app-sprout-slider-poll-label>
          </span>
          <mat-slider
            [formControlName]="field.key"
            [id]="field.key"
            class="sprout-likert"
            [min]="field.minValue"
            [max]="field.maxValue"
            [step]="1"
            [thumbLabel]="true"
            [tickInterval]="1"
          >
          </mat-slider>
          <span class="text-small sprout-likert-label-pole">
            <app-sprout-slider-poll-label [poll]="'max'" [field]="field"></app-sprout-slider-poll-label>
          </span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="SproutFieldType.likert">
      <div *ngIf="field.display !== false">
        <h4>
          <app-sprout-label [form]="form" [field]="field"></app-sprout-label>
        </h4>

        <div>
          <app-sprout-likert [form]="form" [field]="field"></app-sprout-likert>
        </div>
      </div>
    </div>



    <div *ngSwitchDefault>
      <h3>Invalid field type!</h3>
    </div>

  </div>

</div>
