import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {SproutForm} from '../../interfaces/sprout-form';
import {FormGroup} from '@angular/forms';
import {SproutFormControlService} from '../../services/sprout-form-control.service';
import {SproutExpressionService} from '../../services/sprout-expression.service';
import {LocaleService} from '../../services/locale.service';
import {MatStepper} from '@angular/material/stepper';
import {SproutFormDataService} from "../../services/sprout-form-data.service";
import {InstanceTO} from "../../classes/instance-to";
import {interval} from "rxjs";
import {InstanceOutputListTO} from "../../classes/instance-output-list-to";
import {InstanceOutputTO} from "../../classes/instance-output-to";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {SproutFormMetaService} from "../../services/sprout-form-meta.service";
import {StatusTO} from "../../classes/status-to";
import {SproutFieldType} from "../../enums/sprout-field-type.enum";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-sprout-form-container',
  templateUrl: './sprout-form.component.html',
  styleUrls: ['./sprout-form.component.css'],
  providers: [SproutFormControlService, SproutExpressionService]
})
export class SproutFormComponent implements OnInit, AfterViewInit {

  @Input()
  sproutForm: SproutForm;

  @ViewChild('downloadLink')
  private downloadLink: ElementRef;

  form: FormGroup;

  public i18n: any;

  showOrHideFormValue = 'Show';
  debugInd: boolean;
  formMode = 'open';
  progressBarPercentage = 0;

  @ViewChild('stepper') public sproutFormsStepperComponent: MatStepper = null;

  @ViewChildren('pages') private calculatedPages;
  totalStepsCount: number;

  lessPages = false;
  morePages = false;

  isLinear = true;

  public instanceOutputListTO: InstanceOutputListTO;
  private pageFieldMap: any;
  private sproutFormFieldFocus: string;
  submittingOrSaving = false;

  constructor(private sproutFormControlService: SproutFormControlService,
              private sproutExpressionService: SproutExpressionService,
              private localeService: LocaleService,
              private sproutFormDataService: SproutFormDataService,
              private sproutFormMetaService: SproutFormMetaService,
              private sanitizer: DomSanitizer) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculatePaginationButtons();
      this.calculateCompletePages();
      this.gotoLastLocation();
    });
    if (this.sproutFormsStepperComponent) {
      this.totalStepsCount = this.sproutFormsStepperComponent._steps.length;
      this.sproutFormsStepperComponent._getIndicatorType = () => 'number';
    } else {
      this.totalStepsCount = 0;
    }

  }

  ngOnInit() {
    this.form = this.sproutFormControlService.toFormGroup(this.sproutForm.model);
    if (this.sproutForm.value !== undefined) {
      setTimeout(() => {
        // console.log('patchValue!');
        // TODO: add logic to patch ArrayList
        this.form.patchValue(this.sproutForm.value);
      }, 200);
    }

    this.pageFieldMap = [];

    this.sproutForm.model.forEach(page => {
      const fieldArray: string[] = [];

      if (page.composites && page.composites.length > 0) {
        page.composites.forEach(composite => {
          if (composite.fields && composite.fields.length > 0) {
            composite.fields.forEach(field => {

              // console.log('field.type: ' + field.controlType);
              if (field.controlType && field.controlType === SproutFieldType.row) {
                if (field.fields && field.fields.length > 0) {
                  field.fields.forEach(fieldRow => {
                    if (fieldRow.modelInd === true) {
                      // console.log('fieldRow.key: ' + page.name + " ==> " + fieldRow.key);
                      fieldArray.push(fieldRow.key)
                    }
                  });
                }
              } else {
                if (field.key) {
                  fieldArray.push(field.key);
                }
              }
            });
          }
        });
      }
      this.pageFieldMap.push(fieldArray);
    });

    const parent = this;

    let timerAutoSave = -1;
    let runnerAutoSave = function() {
      timerAutoSave = -1;
      parent.autoSave();
    }

    this.form.valueChanges.subscribe(value => {
      this.sproutFormMetaService.setFormValue(this.form.value);
      this.calculateCompletePages();
      if (timerAutoSave !== -1) {
        clearTimeout(timerAutoSave);
      }
      timerAutoSave = setTimeout(function () {
        runnerAutoSave()
      }, 5000); // auto-save every 5 seconds (assuming template has been changed)


    });

    this.sproutFormMetaService.fieldFocus$.subscribe(fieldKey => {
      // console.log('fieldFocus: ' + fieldKey);
      this.sproutFormFieldFocus = fieldKey as string;
      // console.log('this.sproutFormFieldFocus: ' + this.sproutFormFieldFocus);
      // sessionStorage.setItem('sproutFormFieldFocus', fieldKey as string);
      // if (this.sproutFormsStepperComponent) {
        // sessionStorage.setItem('sproutFormStepFocus', this.sproutFormsStepperComponent.selectedIndex + '')
      // }
    });


    this.i18n = this.localeService.translations;

    this.sproutFormMetaService.setStatus(new StatusTO('open', null, null));

    this.localeService._locale$.subscribe(locale => {
      if (this.sproutForm && this.sproutForm.model && this.sproutForm.model.length > 0) {
        this.sproutForm.model.forEach(page => {
          this.sproutExpressionService.compileObservable(page.label, this.form.value).subscribe(result => {
            page.labelLocalized = result;
          }).unsubscribe();
        });
      }
      // this.field.labelLocalized = this.sproutExpressionService.compile(this.field.label, this.form.value);
      // this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);
    });

    this.calculatePaginationButtons();
    this.calculateCompletePages();

    // The following line are for development purposes to jump the
    // developer directly to the output page; not for production!
    //
    // const instanceTO = this.sproutFormDataService.instance;
    //
    // this.sproutFormDataService.getSproutFormOutputList(instanceTO).subscribe(instanceOutputListTORaw => {
    //   const instanceOutputListTO = instanceOutputListTORaw as InstanceOutputListTO;
    //     this.formMode = 'output';
    //     this.instanceOutputListTO = instanceOutputListTO;
    // });
  }

  // ngOnInit() {
  //   this.form = this.sproutFormControlService.toFormGroup(this.sproutForm.model);
  //
  //   this.submitButtonText.labelLocalized = this.sproutExpressionService.compile(this.submitButtonText.label, this.form.value);
  //   this.previousButtonText.labelLocalized = this.sproutExpressionService.compile(this.previousButtonText.label, this.form.value);
  //   this.nextButtonText.labelLocalized = this.sproutExpressionService.compile(this.nextButtonText.label, this.form.value);
  //
  //   this.localeService._locale$.subscribe(locale => {
  //     if (this.sproutForm && this.sproutForm.model && this.sproutForm.model.length > 0) {
  //       this.sproutForm.model.forEach(page => {
  //         page.labelLocalized = this.sproutExpressionService.compile(page.label, this.form.value);
  //       });
  //       this.submitButtonText.labelLocalized = this.sproutExpressionService.compile(this.submitButtonText.label, this.form.value);
  //       this.previousButtonText.labelLocalized = this.sproutExpressionService.compile(this.previousButtonText.label, this.form.value);
  //       this.nextButtonText.labelLocalized = this.sproutExpressionService.compile(this.nextButtonText.label, this.form.value);
  //     }
  //     // this.field.labelLocalized = this.sproutExpressionService.compile(this.field.label, this.form.value);
  //     // this.labelHasExpression = this.sproutExpressionService.hasVariables(this.field.label);
  //   });
  //
  //   this.calculatePaginationButtons();
  //
  // }
  outputHtml: string;
  outputPdf: any;

  calculatePaginationButtons() {
    // console.log('calculatePaginationButtons');
    if (this.sproutFormsStepperComponent) {
      setTimeout(() => {
        this.lessPages = this.sproutFormsStepperComponent.selectedIndex > 0;

        if (this.calculatedPages !== undefined) {
          this.totalStepsCount = this.calculatedPages.toArray().length;
        }

        this.morePages = this.sproutFormsStepperComponent.selectedIndex < (this.totalStepsCount - 1);
        this.sproutFormMetaService.setPageDirty(false);
      });
    }
  }

  stepHeaderClick() {
    console.log(`stepHeaderClick`);
    this.sproutFormMetaService.setPageDirty(true);
  }

  toggleFormValue() {
    this.showOrHideFormValue = this.showOrHideFormValue === 'Show' ? 'Hide' : 'Show';
    this.debugInd = !this.debugInd;
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  onSubmit() {

    this.submittingOrSaving = true;
    // console.log('onSubmit.model:');
    // console.dir(this.form.value);

    if (this.sproutFormDataService.instance) {
      this.sproutFormDataService.submitForm(this.form.value, 'submit', this.getLocation()).subscribe(instanceRaw => {
        const instanceTO = instanceRaw as InstanceTO;
        this.formMode = 'complete';

        this.sproutFormMetaService.setStatus(new StatusTO('complete', null, null));


        // console.log('submitForm.resulting.instanceTO:');
        // console.dir(instanceTO);
        this.sproutFormDataService.instance = instanceTO;

        // interval(1000).subscribe(x => {
        //   this.sproutFormDataService.getSproutFormOutputList(instanceTO).subscribe(instanceOutputListTORaw => {
        //     const instanceOutputListTO = instanceOutputListTORaw as InstanceOutputListTO;
        //
        //     // console.log('instanceOutputListTO:');
        //     // console.dir(instanceOutputListTO);
        //
        //     this.progressBarPercentage = instanceOutputListTO.progress;
        //
        //     if (instanceOutputListTO.complete) {
        //       x = undefined;
        //       clearInterval(x);
        //       this.formMode = 'output';
        //       this.sproutFormMetaService.setStatus(new StatusTO('output', null, null));
        //       this.instanceOutputListTO = instanceOutputListTO;
        //     }
        //   });
        // });
      });
    } else {
      console.log('This feature is not available in designer mode.');
      // this._snackBar.open('This feature is not available in designer mode.', 'OK', {
      //   duration: 2000,
      // });
      this.submittingOrSaving = false;
    }

  }

  getLocation() {
    // console.log('getLocation...');
    // console.log('getLocation.this.sproutFormsStepperComponent: ' + this.sproutFormsStepperComponent);
    // console.log('getLocation.this.sproutFormFieldFocus: ' + this.sproutFormFieldFocus);
    if (this.sproutFormsStepperComponent && this.sproutFormFieldFocus) {
      const location: string = this.sproutFormsStepperComponent.selectedIndex + ':' + this.sproutFormFieldFocus;
      // console.log('getLocation.location: ' + location);
      return location;
    }
    return '';
  }


  onSaveAndQuit() {
    // console.log('onSaveAndQuit.model:');
    // console.dir(this.form.value);

    this.submittingOrSaving = true;

    if (this.sproutFormDataService.instance) {
      this.sproutFormDataService.submitForm(this.form.value, 'save', this.getLocation()).subscribe(instanceRaw => {
        const instanceTO = instanceRaw as InstanceTO;
        this.formMode = 'saved';
        this.sproutFormMetaService.setStatus(new StatusTO('saved', null, null));

        // console.log('submitForm.resulting.instanceTO:');
        // console.dir(instanceTO);
        this.sproutFormDataService.instance = instanceTO;
      });
    } else {
      console.log('This feature is not available in designer mode.');
      // this._snackBar.open('This feature is not available in designer mode.', 'OK', {
      //   duration: 2000,
      // });
      this.submittingOrSaving = false;
    }

  }

  autoSave() {
    if (this.sproutFormDataService.instance &&  this.formMode !== 'complete' && !this.submittingOrSaving) {
      this.sproutFormDataService.submitForm(this.form.value, 'autosave', this.getLocation()).subscribe(instanceRaw => {
        this.sproutFormDataService.instance = instanceRaw as InstanceTO;
      });
    }
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustUrl(oldURL);
  }

  openOutput(instanceOutputTO: InstanceOutputTO) {
    this.outputHtml = undefined;
    this.outputPdf = undefined;
    this.sproutFormDataService.getSproutFormOutput(instanceOutputTO).subscribe(instanceOutputTORaw => {
      const instanceOutputTO = instanceOutputTORaw as InstanceOutputTO;

      // console.log('instanceOutputTO.data');
      // console.dir(instanceOutputTO);

      if (instanceOutputTO.contentType && instanceOutputTO.contentType == 'text/html') {
        this.outputHtml = atob(instanceOutputTO.data);
      } else if (instanceOutputTO.contentType && instanceOutputTO.contentType == 'application/pdf') {

        // console.log('instanceOutputTO.data:');
        // console.dir(instanceOutputTO.data);

        const url = window.URL.createObjectURL(this.base64toBlob(instanceOutputTO.data, "application/pdf"));

        const link = this.downloadLink.nativeElement;
        link.href = url;
        link.download = instanceOutputTO.name + '.pdf';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    });
  }

  base64toBlob = function (base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }









  @ViewChild('stepperTest') stepperTest: MatStepper;

  nextClicked(event) {
    // complete the current step
    this.stepperTest.selected.completed = true;
    // move to next step
    this.stepperTest.next();
  }


  private calculateCompletePages() {
    // console.log('calculateCompletePages');

    if (this.pageFieldMap) {
      if (this.sproutFormsStepperComponent) {
        setTimeout(() => {
            this.sproutFormsStepperComponent._steps.forEach((step, index) => {

            const fields = this.pageFieldMap[index];

            let pageValid = true;

            fields.forEach(field => {
              if (this.form && this.form.controls[field] && this.form.controls[field].invalid) pageValid = false;
            });

            step.completed = pageValid;
          });
        });
      }

      this.pageFieldMap.forEach(field => {

      });
    }



  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  private gotoLastLocation() {

    // console.log('%c gotoLastLocation.location: ' + this.sproutFormDataService.instance.location, 'background: #222; color: #bada55');
    // console.dir(this.sproutFormDataService.instance.location);

    if (this.sproutFormDataService.instance && this.sproutFormDataService.instance.location) {
      const location = this.sproutFormDataService.instance.location;
      if (location && location.split(":").length == 2) {
        const locationStep: number = location.split(':')[0] as any as number;
        const locationField: string = location.split(':')[1] as string;

        // console.log('gotoLastLocation.locationStep: ' + locationStep);
        // console.log('gotoLastLocation.locationField: ' + locationField);

        this.sproutFormsStepperComponent.linear = false;
        this.sproutFormsStepperComponent.selectedIndex = locationStep;
        setTimeout(() => {
          this.sproutFormsStepperComponent.linear = true;
          if (document.getElementById(locationField)) {
            document.getElementById(locationField).scrollIntoView();
          }
        });

        // this.sproutFormsStepperComponent.selectedIndex = locationStep + 1;
        // document.getElementById(locationField).click();
      }
    }
  }
}
