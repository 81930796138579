<!--<mat-toolbar color="primary">-->
<!--  <mat-toolbar-row>-->
<!--    <span class="fill-remaining-space">Welcome to Sprout 2.0: {{sproutForm?.meta?.formName}}</span>-->
<!--  </mat-toolbar-row>-->
<!--</mat-toolbar>-->

<div style="text-align:right" class="mat-typography" >
  <form [formGroup]="localePickerForm" *ngIf="localeService.showDropdown && locales && locales.length > 1">
    <mat-form-field class="sprout-full-width">
      <mat-select
        formControlName="locale"
      >
        <mat-option *ngFor="let locale of locales" [value]="locale">
          {{locale.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <app-sprout-form-container *ngIf="sproutForm && sproutForm.model && sproutForm.model.length > 0" [sproutForm]="sproutForm" ></app-sprout-form-container>

</div>
