import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutCalculationField extends SproutFieldBase<string> {
  fieldType = 'SproutCalculationField';
  controlType = SproutFieldType.calculation;
  modelInd: true;
  type: string;
  expression: string;
  show: Boolean = false;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.expression = options['expression'] || '';
    this.dataType = SproutDataType.text;
    this.show = options['show'] === true;
  }
}
