<div style="padding: 20px;">

  <mat-card class="sprout-card-warning" *ngIf="!initComplete && i18n['loadingPleaseWait']">
    {{i18n['loadingPleaseWait']}}
  </mat-card>

  <div *ngIf="initComplete">

    <mat-card>

      <mat-card-title>
        {{i18n['myFormsHeader']}}
        <span style="float: right; margin-right: 20px;">
      <button mat-raised-button (click)="onManageSubscriptions()" *ngIf="subscriber" style="margin-right: 10px;"><mat-icon>subscriptions</mat-icon>
        {{i18n['manageSubscriptions']}}</button>
      <button mat-raised-button (click)="onNewForm()"><mat-icon>post_add</mat-icon>
        {{i18n['newFormButton']}}</button>
    </span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="instances && instances.length > 0">{{i18n['myFormsText']}}
      </mat-card-subtitle>
      <mat-card-content>

        <div class="example-container mat-elevation-z8">
          <mat-table *ngIf="instances && instances.length > 0" [dataSource]="instances" class="mat-elevation-z8"
                     [class.isMobile]="isMobile">
            <!-- Name Column -->
            <ng-container matColumnDef="name" class="mat-column-name">
              <mat-header-cell *matHeaderCellDef>

                {{i18n['myFormsName']}}

<!--                <span *ngIf="!nameFilter">{{i18n['myFormsName']}} <span class="name-filter" (click)="onToggleFilter()"><mat-icon>filter_alt</mat-icon></span></span>-->
<!--                <span *ngIf="nameFilter">-->
<!--                  <mat-form-field appearance="standard">-->
<!--                    <mat-label>Name Filter</mat-label>-->
<!--                    <input matInput (ngModelChange)="onFilter($event)" [(ngModel)]="filter">-->
<!--                  </mat-form-field>-->
<!--                  <span class="name-filter" (click)="onToggleFilter()"><mat-icon>filter_alt</mat-icon></span>-->
<!--                </span>-->


              </mat-header-cell>
              <mat-cell *matCellDef="let instance; let i = index">
<!--              <mat-cell *matCellDef="let instance; let i = index" style="min-width: 250px;">-->
                <span class="mobile-label">{{i18n['myFormsName']}}:</span>

                <span *ngIf="!editMap[i]">
                  <span *ngIf="instance.name">{{instance.name}}</span>
                  <span *ngIf="!instance.name">{{instance.publicationName}}</span>
                </span>
                <span *ngIf="editMap[i]">
                  <mat-form-field appearance="standard" style="max-width: 90%;">
                    <mat-label>Rename</mat-label>
                    <input matInput (keydown)="onRenameKeydown($event, i, instance)" [(ngModel)]="editValueMap[i]">
                    <!--                    <input matInput (keydown)="onRenameKeydown($event, i, instance)" [(ngModel)]="instance.name">-->
                    <!--                    <input matInput [value]="instance.name ? instance.name : instance.publicationName" [(ngModel)]="instance.name">-->
                  </mat-form-field>
                </span>

                <span *ngIf="!editMap[i]" class="rename-button" (click)="onToggleEdit(i, instance)" matTooltip="Rename"
                      [matTooltipShowDelay]="showDelay"><mat-icon>drive_file_rename_outline</mat-icon></span>
                <span *ngIf="editMap[i]" class="save-button" (click)="onSaveEdit(i, instance)" matTooltip="Save Changes"
                      [matTooltipShowDelay]="showDelay"><mat-icon>save</mat-icon></span>
                <span *ngIf="editMap[i]" class="cancel-button" (click)="onToggleEdit(i, instance)" matTooltip="Cancel"
                      [matTooltipShowDelay]="showDelay"><mat-icon>cancel</mat-icon></span>
              </mat-cell>
              <!--        <td mat-footer-cell *matFooterCellDef></td>-->
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="statusName" class="mat-column-other">
              <mat-header-cell *matHeaderCellDef> {{i18n['myFormsStatus']}}</mat-header-cell>
              <mat-cell class="mat-column-other" *matCellDef="let instance">
                <span class="mobile-label">{{i18n['myFormsStatus']}}:</span>
                {{i18n['formStatus-' + instance.statusCode]}}
                <!--          {{instance.statusName}} -->
              </mat-cell>
              <!--        <td mat-footer-cell *matFooterCellDef></td>-->
            </ng-container>

            <!-- Timestamp Column -->
            <ng-container matColumnDef="activityDate">
              <mat-header-cell *matHeaderCellDef> {{i18n['myFormsDate']}}</mat-header-cell>
              <mat-cell class="mat-column-other" *matCellDef="let instance">
                <span class="mobile-label">{{i18n['myFormsDate']}}:</span>
                {{instance.activityDate|date:'medium': '' : localeCode}} </mat-cell>
            </ng-container>

            <!-- Open Column -->
            <ng-container matColumnDef="form">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <!--      <mat-cell *matCellDef="let instance"> <button mat-raised-button (click)="onOpenForm(instance)"><mat-icon>assignment</mat-icon> Open Form</button> </td>-->
              <mat-cell class="mat-column-button" *matCellDef="let instance">
                <button class="sprout-inbox-button" [disabled]="instance.statusCode == 'COMPLETE'" mat-raised-button
                        color="primary"
                        (click)="onOpenForm(instance)">
                  <mat-icon>assignment</mat-icon>
                  {{i18n['openFormButton']}}
                </button>
              </mat-cell>
              <!--        <td mat-footer-cell *matFooterCellDef></td>-->
            </ng-container>

            <!-- View Output Column -->
            <ng-container matColumnDef="output">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <!--      <mat-cell *matCellDef="let instance"> <button mat-raised-button (click)="onOpenOutput(instance)"><mat-icon>speaker_notes</mat-icon> View Output</button> </td>-->
              <mat-cell *matCellDef="let instance">
                <button class="sprout-inbox-button" [disabled]="instance.statusCode != 'COMPLETE'" mat-raised-button
                        color="primary"
                        (click)="onOpenOutput(instance)">
                  <mat-icon>speaker_notes</mat-icon>
                  {{i18n['viewOutputButton']}}
                </button>
              </mat-cell>
              <!--      <mat-cell *matCellDef="let instance"> <button mat-raised-button color="primary" (click)="onOpenOutput(instance)"><mat-icon>open_in_new</mat-icon> View Output</button> </td>-->


              <!--        <td mat-footer-cell *matFooterCellDef>-->
              <!--          <button mat-raised-button  (click)="onNewForm()"><mat-icon>post_add</mat-icon> New Form</button>-->
              <!--        </td>-->
            </ng-container>

            <mat-header-row class="sprout-inbox-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <!--      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
          </mat-table>
        </div>

        <!--    <mat-card [style.backgroundColor]="'yellow'" *ngIf="initComplete && !instances">-->
        <mat-card class="sprout-card-warning" *ngIf="initComplete && (!instances || instances.length == 0)">
          {{i18n['noFormsOne']}} <a [href]="" style="cursor: pointer; font-weight: bold;"
                                    (click)="onNewForm()">{{i18n['newFormButton']}}</a> {{i18n['noFormsThree']}}
        </mat-card>
        <mat-card class="sprout-card-warning" *ngIf="!initComplete">
          {{i18n['loadingPleaseWait']}}
        </mat-card>

      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>


  </div>
</div>


