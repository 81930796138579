import {NgModule} from '@angular/core';
import {SproutFormsLibraryComponent} from './sprout-forms-library.component';
import {ViewerComponent} from "./components/viewer/viewer.component";
import {SproutFormComponent} from "./components/sprout-form/sprout-form.component";
import {SproutFieldComponent} from "./components/sprout-field/sprout-field.component";
import {SproutLabelComponent} from "./components/sprout-label/sprout-label.component";
import {SproutSliderPollLabelComponent} from "./components/sprout-slider-poll-label/sprout-slider-poll-label.component";
import {SproutLikertComponent} from "./components/sprout-likert/sprout-likert.component";
import {SproutCompositeComponent} from "./components/sprout-composite/sprout-composite.component";
import {SproutPageComponent} from "./components/sprout-page/sprout-page.component";
import {SproutRowComponent} from "./components/sprout-row/sprout-row.component";
import {SproutPagePipe} from "./pipes/sprout-page.pipe";
import {DesignerComponent} from "./components/designer/designer.component";
import {SproutDateComponent} from "./components/sprout-date/sprout-date.component";
import {MaskDateDirective} from "./directives/mask-date.directive";
import {MatVerticalStepperScrollerDirective} from "./directives/mat-vertical-stepper-scroller.directive";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from "@angular/forms";

import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTreeModule} from '@angular/material/tree';
import {MaterialModule} from './modules/material.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {SafePipe} from "./pipes/safe.pipe";
import 'hammerjs';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatListModule} from "@angular/material/list";
import {InboxComponent} from './components/inbox/inbox.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {MatTableModule} from "@angular/material/table";
import { SproutFieldSelectorDirective } from './directives/sprout-field-selector.directive';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {MatTooltipModule} from "@angular/material/tooltip";

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    SproutFormsLibraryComponent,
    SproutFormComponent,
    SproutFieldComponent,
    SproutLabelComponent,
    SproutSliderPollLabelComponent,
    SproutLikertComponent,
    SafePipe,
    SproutCompositeComponent,
    SproutPageComponent,
    SproutRowComponent,
    SproutPagePipe,
    DesignerComponent,
    ViewerComponent,
    SproutDateComponent,
    MaskDateDirective,
    MatVerticalStepperScrollerDirective,
    InboxComponent,
    SafeHtmlPipe,
    SproutFieldSelectorDirective,
  ],
    imports: [
        CommonModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        MatSidenavModule,
        MatIconModule,
        MatTreeModule,
        DragDropModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatListModule,
        MatTableModule,
        MatTooltipModule
    ],
  exports: [
    SproutFormsLibraryComponent,
    InboxComponent
  ]
})
export class SproutFormsLibraryModule {

  constructor() { }

}
