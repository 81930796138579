<div [ngSwitch]="formMode">






  <div *ngSwitchCase="'open'" layout-align="center left" class="sprout-form-master" layout="row">
    <mat-card class="sprout-card">
      <mat-card-content>


<!--        <mat-vertical-stepper linear #stepperTest>-->
<!--          <mat-step completed="false">-->
<!--            <ng-template matStepLabel>Step 1</ng-template>-->
<!--            stuff1-->
<!--            <button mat-button mat-raised-button color="accent" (click)="nextClicked($event)" >Next</button>-->
<!--          </mat-step>-->
<!--          <mat-step>-->
<!--            <ng-template matStepLabel>Step 2</ng-template>-->
<!--            stuff2-->
<!--            <button mat-button mat-raised-button color="accent" (click)="nextClicked($event)" >Next</button>-->
<!--          </mat-step>-->
<!--        </mat-vertical-stepper>-->


        <mat-vertical-stepper [linear]="form.invalid"  appMatVerticalStepperScroller #stepper *ngIf="sproutForm.model && sproutForm.model.length > 1" (selectionChange)="calculatePaginationButtons()">
          <mat-step completed="false" #pages *ngFor="let page of sproutForm.model | sproutPage" class="form-row">
            <ng-template matStepLabel>
              <div class="mat-step-custom-click" (click)="stepHeaderClick()">
                {{page.labelLocalized}}
              </div>
            </ng-template>
            <app-sprout-page [page]="page" [composites]="page.composites" [form]="form"></app-sprout-page>
          </mat-step>
        </mat-vertical-stepper >



<!--        <mat-vertical-stepper [linear]="form.invalid" appMatVerticalStepperScroller #stepper *ngIf="sproutForm.model && sproutForm.model.length > 1" (selectionChange)="calculatePaginationButtons()">-->
<!--          <mat-step completed="false" #pages [completed]="false" [stepControl]="page.name" *ngFor="let page of sproutForm.model | sproutPage" class="form-row">-->
<!--            <ng-template matStepLabel>{{page.labelLocalized}}</ng-template>-->
<!--            <app-sprout-page [page]="page" [composites]="page.composites" [form]="form"></app-sprout-page>-->
<!--          </mat-step>-->
<!--        </mat-vertical-stepper >-->










        <div *ngIf="sproutForm.model && sproutForm.model.length <= 1">
          <div *ngFor="let page of sproutForm.model" class="form-row">
            <app-sprout-page [composites]="page.composites" [form]="form"></app-sprout-page>
          </div>
        </div>

        <div *ngIf="debugInd">
          <pre>{{form.value|json}}</pre>
        </div>

        <span class="sprout-form-button-row">
        <button mat-raised-button color="primary" class="sprout-controls" (click)="onSubmit()" type="submit" [disabled]="form.invalid || submittingOrSaving">{{i18n['submitButtonText']}}</button>
        <button mat-raised-button color="success" class="sprout-controls" (click)="onSaveAndQuit()" type="submit" [disabled]="submittingOrSaving">{{i18n['saveButtonText']}}</button>
          <span *ngIf="sproutForm.model && sproutForm.model.length > 1">
            <button mat-raised-button (click)="goBack(sproutFormsStepperComponent)" [disabled]="!lessPages">{{i18n['previousButtonText']}}</button>
            <button mat-raised-button (click)="goForward(sproutFormsStepperComponent)" [disabled]="!morePages || !sproutFormsStepperComponent.selected.completed" color="accent">{{i18n['nextButtonText']}}</button>
          </span>
        </span>

<!--        <button mat-raised-button (click)="toggleFormValue()" style="float: right;">{{showOrHideFormValue}} Form Data</button>-->
<!--        <button mat-raised-button color="accent" class="sprout-controls" type="button" (click)="toggleFormValue()">{{showOrHideFormValue}} Form Data</button>-->
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngSwitchCase="'complete'" layout-align="center left" class="sprout-form-master" layout="row">
    <div class="alert alert-success" style="width: 100%; margin: 15px;">
      <h3>You have successfully submitted your form. Please wait while we generate your report.</h3>

      <h2>Progress</h2>
      <mat-progress-bar mode="determinate" [value]="progressBarPercentage"></mat-progress-bar>

    </div>

  </div>
  <div *ngSwitchCase="'saved'" layout-align="center left" class="sprout-form-master" layout="row" >
    <div class="alert alert-success" style="width: 100%; margin: 15px;">
      <h3>You have successfully saved your form. You may return this form and complete it later.</h3>
    </div>
  </div>
  <div *ngSwitchCase="'output'" class="sprout-form-master"  >
    <h2>Output</h2>

    <div class="row">
      <div></div>
      <mat-list *ngIf="instanceOutputListTO && instanceOutputListTO.outputReferences">
        <mat-list-item *ngFor="let instanceOutputTO of instanceOutputListTO.outputReferences">
          <!--        <mat-icon matListIcon>folder</mat-icon>-->
          <div matLine>
            <a (click)="openOutput(instanceOutputTO)" style="cursor: pointer;">
              <i class="material-icons">
                open_in_browser
              </i>
            </a>
            <div matLine> {{instanceOutputTO.name}} </div>

          </div>

        </mat-list-item>
      </mat-list>
    </div>

<!--    <div class="row" *ngIf="outputHtml">-->
<!--      <div [innerHTML]="outputHtml"></div>-->
<!--    </div>-->

  </div>
  <a class="download-link" #downloadLink></a>
  <mat-card *ngIf="outputHtml" style="text-align: left;"><div [innerHTML]="outputHtml | safeHtml"></div></mat-card>
  <mat-card *ngIf="outputPdf" style="text-align: left;"><iframe [src]="cleanURL(outputPdf)"></iframe></mat-card>
</div>

