import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutTextareaField extends SproutFieldBase<string> {
  META_NAME = 'Textarea Field';

  controlType = SproutFieldType.textarea;
  modelInd: true;
  type: string;
  dataType: SproutDataType;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.dataType = options['dataType'] || SproutDataType.text;
  }
}
