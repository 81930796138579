import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';
import {SafeStyle} from '@angular/platform-browser';
import {SproutFieldOption} from '../interfaces/sprout-field-option';
import {SproutFieldValidator} from '../enums/sprout-field-validator.enum';

export class SproutFieldBase<T> {
  name: string;
  fieldType = 'SproutFieldBase';
  value: T;
  key: string;
  label: any;
  labelLocalized: string;
  required: boolean;
  requiredExpression: string;
  order: number;
  controlType: SproutFieldType;
  dataType: SproutDataType;
  conditionalExpression: string;
  expression: string;
  class: string;
  style: string;
  safe: boolean;
  colspan: number;
  styleSanitized: SafeStyle;
  modelInd = true;
  displayHelp = false;
  questions: SproutFieldOption[];
  options: SproutFieldOption[];
  fieldValidator: SproutFieldValidator;

  // Thjs is possible for SproutRow classes
  public fields: SproutFieldBase<any>[];

  display = true;

  minValue: number;
  maxValue: number;

  constructor(options: {
    name?: string,
    value?: T,
    key?: string,
    label?: any,
    required?: boolean,
    requiredExpression?: string,
    order?: number,
    dataType?: SproutDataType,
    controlType?: SproutFieldType,
    conditionalExpression?: string,
    expression?: string,
    class?: string,
    style?: string,
    safe?: boolean,
    colspan?: number,
    modelInd?: boolean,
    questions?: SproutFieldOption[],
    options?: SproutFieldOption[],
  } = {}) {
    this.value = options.value;
    // // @ts-ignore
    // this.value = (options.value === undefined) ? '' : options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.requiredExpression = options.requiredExpression || null;
    this.order = options.order === undefined ? 1 : options.order;
    this.dataType = options.dataType === undefined ? SproutDataType.text : options.dataType;
    this.controlType = options.controlType || null;
    this.conditionalExpression = options.conditionalExpression || null;
    this.expression = options.expression || null;
    this.class = options.class || null;
    this.style = options.style || null;
    this.safe = options.safe || false;
    this.colspan = options.colspan || 1;
    this.modelInd = (options.modelInd !== undefined) ? options.modelInd : true;
    this.questions = options.questions;
    this.options = options.options;
  }
}
