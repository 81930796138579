import { Pipe, PipeTransform } from '@angular/core';
import {SproutPage} from '../classes/sprout-page';

@Pipe({
  name: 'sproutPage',
  pure: false
})
export class SproutPagePipe implements PipeTransform {
  transform(pages: SproutPage[]): any {
    if (!pages) {
      return pages;
    }
    return pages.filter(page => {
      return page.display;
    });
  }
}
