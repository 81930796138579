export enum SproutDataType {
  text = 'text',
  password = 'password',
  submit = 'submit',
  reset = 'reset',
  radio = 'radio',
  checkbox = 'checkbox',
  button = 'button',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  number = 'number',
  email = 'email',
  month = 'month',
  range = 'range',
  likert = 'likert',
  row = 'row',
  search = 'search',
  tel = 'tel',
  time = 'time',
  url = 'url',
  week = 'week',
}
