import {SproutFieldBase} from './sprout-field-base';
import {SproutFieldType} from '../enums/sprout-field-type.enum';
import {SproutDataType} from '../enums/sprout-data-type.enum';

export class SproutDivField extends SproutFieldBase<string> {
  fieldType = 'SproutDivField';
  controlType = SproutFieldType.div;
  modelInd: false;
  type: string;
  dataType: SproutDataType;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.dataType = options['dataType'] || SproutDataType.text;
  }
}
